/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./self-registration-success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./self-registration-success.component";
import * as i3 from "@angular/router";
import * as i4 from "angular-persistence/src/services/persistence.service";
import * as i5 from "../../../../core/services/manage-user-shared.service";
var styles_SelfRegistrationSuccessComponent = [i0.styles];
var RenderType_SelfRegistrationSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelfRegistrationSuccessComponent, data: {} });
export { RenderType_SelfRegistrationSuccessComponent as RenderType_SelfRegistrationSuccessComponent };
export function View_SelfRegistrationSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "registration-success-container panel-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "clean"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "clean"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "login-contain-btn btn-align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["id", "login"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirectLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateString.CONGRATS_TXT; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.templateString.ACCOUNT_CREATED_TXT; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.templateString.LOGIN_BTN; _ck(_v, 7, 0, currVal_2); }); }
export function View_SelfRegistrationSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-self-registration-success", [], null, null, null, View_SelfRegistrationSuccessComponent_0, RenderType_SelfRegistrationSuccessComponent)), i1.ɵdid(1, 4308992, null, 0, i2.SelfRegistrationSuccessComponent, [i3.Router, i4.PersistenceService, i5.ManageUserSharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelfRegistrationSuccessComponentNgFactory = i1.ɵccf("app-self-registration-success", i2.SelfRegistrationSuccessComponent, View_SelfRegistrationSuccessComponent_Host_0, { step: "step", userDetails: "userDetails" }, {}, []);
export { SelfRegistrationSuccessComponentNgFactory as SelfRegistrationSuccessComponentNgFactory };
