import { Injector } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';
import { AppConfigService } from './app-config.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
import * as i3 from "ngx-cookie-service";
/**
 * remote logging service for sending errors to API for standard logging solution
 */
export class RemoteLoggingService {
    /** standard constructor for services */
    constructor(injector, http, appConfig, cookieService) {
        this.injector = injector;
        this.http = http;
        this.appConfig = appConfig;
        this.cookieService = cookieService;
    }
    /**
     * Post message to server
     * @param logDescription the description to log
     * @param url the URL
     * @param stack the callstack, if available
     * @param isError is the message an error
     */
    postToServer(logDescription, url, stack, isError) {
        const headers = {
            'Authorization': this.cookieService.get('car-ses-tok'),
            'app-context': sessionStorage.getItem('car-ses-con'),
            'party-roles': sessionStorage.getItem('car-ses-rol')
        };
        let titleurl = isError ? 'Error: ' : 'Info: ';
        titleurl += url;
        const error = {
            processName: 'go',
            title: titleurl,
            description: logDescription,
            details: `${JSON.stringify(headers)}\n\n${stack}`
        };
        const logUrl = this.appConfig.getConfig('logapi');
        const postHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(logUrl + 'v1/error', error, { headers: postHeaders }).pipe(catchError(err => {
            console.error('FAILURE: Unable to log error to API', err);
            return of(null);
        })).toPromise();
    }
    /**
     * log a message
     * @param message the message
     * @param url the URL, if available
     * @param stack the callstack, if available
     * @param isError is the message an error
     */
    log(message, url, stack, isError) {
        const consoleMsg = `Logging to server - message: ${message}  URL: ${url}  stack: ${stack}`;
        if (isError) {
            console.error(consoleMsg);
        }
        else {
            console.log(consoleMsg);
        }
        this.postToServer(message, url, stack, isError);
    }
    /**
     * log an error
     * @param error the error that occurred
     */
    logError(error) {
        try {
            const message = error.message ? error.message : error.toString();
            const url = this.getUrl();
            if (error instanceof Error) {
                // get the stack trace, lets grab the last 10 stacks only
                StackTrace.fromError(error).then(stackFrames => {
                    // log on the server
                    const stackString = stackFrames.map(sf => sf.toString()).join('\n');
                    this.log(message, url, stackString, true);
                });
            }
            else {
                this.log(message, url, 'N/A', true);
            }
        }
        catch (e) {
            this.log('Error while parsing error: ' + error, '', 'N/A', true);
        }
    }
    /** gets the current URL */
    getUrl() {
        // tslint:disable-next-line: deprecation
        const location = this.injector.get(LocationStrategy);
        return location instanceof HashLocationStrategy
            ? (location).path(true) || window.location.toString()
            : window.location.toString();
    }
}
RemoteLoggingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RemoteLoggingService_Factory() { return new RemoteLoggingService(i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.CookieService)); }, token: RemoteLoggingService, providedIn: "root" });
