var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject, of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
/** user service to post details of login to the server
 * header
 */
export class FeatureFlagService {
    constructor(baseClient, logSvc) {
        this.baseClient = baseClient;
        this.logSvc = logSvc;
        this.googleIDPFeatureFlag$ = new BehaviorSubject(null);
    }
    getgoogleIDPFeatureFlag() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.googleIDPFeatureFlag$.value) {
                const response = yield this.getFeatureFlag('enable-google-idp').toPromise();
                this.googleIDPFeatureFlag$.next(response);
            }
            return Promise.resolve(this.googleIDPFeatureFlag$.value && this.googleIDPFeatureFlag$.value.toString() === 'true');
        });
    }
    getFeatureFlag(name) {
        return this.baseClient.getById('/featureFlag/' + name).pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.logSvc.logError(err);
            return of(empty);
        }));
    }
}
FeatureFlagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureFlagService_Factory() { return new FeatureFlagService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService)); }, token: FeatureFlagService, providedIn: "root" });
