var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { Subscription } from 'rxjs';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { Title } from '@angular/platform-browser';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { RemoteLoggingService } from '../../../core/services/remote-logging.service';
export class UpdatePasswordComponent {
    /**Base Constructor for Login Component
    * @param authSvc Authentication Service to authenticate the user details
    */
    constructor(fb, authSvc, spinner, router, authService, cookieService, userPreferencesService, appConfig, liveAnnouncer, snackBar, titleService, cookieLogoSvc, logSvc) {
        this.fb = fb;
        this.authSvc = authSvc;
        this.spinner = spinner;
        this.router = router;
        this.authService = authService;
        this.cookieService = cookieService;
        this.userPreferencesService = userPreferencesService;
        this.appConfig = appConfig;
        this.liveAnnouncer = liveAnnouncer;
        this.snackBar = snackBar;
        this.titleService = titleService;
        this.cookieLogoSvc = cookieLogoSvc;
        this.logSvc = logSvc;
        /** Subscription property for subscribing and unsubscribing services */
        this.subscription = new Subscription();
        /** Model to inject change password object */
        this.passwordCredential = {};
        /*Token Model initialisation */
        this.token = {};
        /** Password Instructions */
        this.passwordInstructions = ['At least 1 number', 'At least 1 capital letter', 'At least 1 lower case', 'At least 1 special character'];
        /*Available Sites*/
        this.authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices'];
        /*To check redirected from authorised site or not*/
        this.authorisedSite = false;
        /* Login Button Status*/
        this.loginButtonStatus = true;
        /* Update password error message */
        this.errorMsg = 'You must enter a password';
        /* Okta session timeout */
        this.idleTimeoutMinutes = 55;
        /** Stores the strings used in the template */
        this.templateString = {
            INFORMATION: 'Please use the form below to create a new password so that you can access your account',
            BANNER_TXT: `Technology That Moves You`,
            PASSWORD_INSTRUCTION_TITLE: `Passwords must be at least 10 characters, and must contain the following details:`,
            PASSWORD_ADDITIONAL_INSTRUCTION: `Additionally, your new password cannot include your username or be one of your last 12 passwords.`,
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirmNewPassword: 'Confirm New Password',
            CHANGE_PASS_BTN: 'Change Password',
            PASSWORD_NOT_MATCH: 'Password entered does not match new password'
        };
        this.userId = this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.userId
            ? this.router.getCurrentNavigation().extras.state.userId : null;
    }
    // Detect window size
    onresize() {
        this.getWindowSize();
    }
    ngOnInit() {
        this.titleService.setTitle('Change Password');
        this.getWindowSize();
        this.createControl();
        this.updateError = '';
        this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
            // Redirect to authorized destination
            this.referredURL = val.replace(/(\/#|\/|#)$/, '');
            const res = this.cookieLogoSvc.setLogo(this.referredURL);
            if (res) {
                this.logo = val;
            }
        });
        // Listener for removal of token cookie sent by header component
        this.checkForCookies();
    }
    /** creates the form controls */
    createControl() {
        this.updatePasswordSetupForm = this.fb.group({
            currentPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required,
                    Validators.pattern('^((?=.{10,})((?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9\s])(?=.*[0-9]))).*$')]],
            confirmNewPassword: [null, Validators.required]
        });
    }
    /** checks the values of the newpassword fields and displays an error if different */
    checkPassword() {
        this.updateError = '';
        this.updatePasswordSetupForm.get('newPassword').value === this.updatePasswordSetupForm.get('confirmNewPassword').value ?
            this.updatePasswordSetupForm.controls['confirmNewPassword'].setErrors(null) :
            this.updatePasswordSetupForm.controls['confirmNewPassword'].setErrors({
                notSame: true
            });
    }
    /** clears the error status on the field if it has an error */
    clearError(fieldName) {
        if (this.updatePasswordSetupForm.controls[fieldName].hasError('invalid')) {
            this.updateError = '';
            this.updatePasswordSetupForm.controls[fieldName].setErrors(null);
        }
    }
    /** handles the errors on the form controls */
    // getErrorMessage(fieldName): (string | undefined) {
    getErrorMessage(fieldName) {
        if (fieldName === 'currentPassword') {
            return this.updatePasswordSetupForm.get(fieldName).hasError('required')
                ? this.errorMsg
                : '';
        }
        if (fieldName === 'newPassword') {
            return this.updatePasswordSetupForm.get(fieldName).hasError('required')
                ? this.errorMsg
                : this.updatePasswordSetupForm.get(fieldName).hasError('pattern')
                    ? 'You must match password complexity rules'
                    : '';
        }
        if (fieldName === 'confirmNewPassword') {
            return this.updatePasswordSetupForm.get(fieldName).hasError('required')
                ? this.errorMsg
                : '';
        }
    }
    /** handles the submission of the change password form */
    changeUserPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            this.spinner.show();
            const _this = this;
            this.passwordCredential.oldPassword = this.updatePasswordSetupForm.controls['currentPassword'].value;
            this.passwordCredential.newPassword = this.updatePasswordSetupForm.controls['newPassword'].value;
            this.subscription.add(this.authSvc
                .changeUserPassword(this.passwordCredential)
                .subscribe(response => {
                if (!!response) { //401 swallowed by http-error-handler
                    let passwordSnackbar;
                    let message;
                    let snackbarMessage;
                    let remainingPasswordAttempts;
                    let announcementMsg;
                    if (response.error && response.error.statusCode && response.error.statusCode === 403) {
                        remainingPasswordAttempts = Number(response.error.remainingAttempts);
                        message = response.error.message.toLowerCase();
                        if (message.includes('your current password is not entered correctly')) {
                            _this.updatePasswordSetupForm.get('currentPassword').setErrors({
                                notCorrect: true
                            });
                            _this.incorrectPasswordError = `Incorrect password, you will be logged out after ${remainingPasswordAttempts} more incorrect attempts.`;
                            announcementMsg = _this.incorrectPasswordError;
                        }
                        else if (message.includes('new password cannot be the same') || message.includes('password cannot be any of the past')) {
                            _this.updatePasswordSetupForm.get('newPassword').setErrors({
                                invalid: true
                            });
                            _this.updateError = `${response.error.message}.`;
                            announcementMsg = _this.updateError;
                            _this.getErrorMessage('newPassword');
                        }
                        else {
                            _this.updatePasswordSetupForm.get('newPassword').setErrors({
                                invalid: true
                            });
                            _this.updateError = response.error.message + `. you will be logged out after ${remainingPasswordAttempts} more incorrect attempts.`;
                            announcementMsg = _this.updateError;
                            _this.getErrorMessage('newPassword');
                        }
                    }
                    else if (response.error && response.error.statusCode && response.error.statusCode !== 403) {
                        snackbarMessage = 'We are unable to process your request at this time. Please try again later.';
                        this.logSvc.logError(response);
                        console.error('Error in change password call');
                    }
                    else {
                        snackbarMessage = 'Changes saved';
                    }
                    if (!!announcementMsg) {
                        _this.liveAnnouncer.announce(announcementMsg);
                        this.spinner.hide();
                    }
                    if (!!snackbarMessage) {
                        passwordSnackbar = this.snackBar.openFromComponent(CustomSnackbarComponent, {
                            horizontalPosition: 'center',
                            verticalPosition: 'bottom',
                            data: snackbarMessage,
                            duration: 5000,
                        });
                        this.spinner.hide();
                        passwordSnackbar.afterDismissed().subscribe(() => __awaiter(this, void 0, void 0, function* () {
                            this.cancel();
                        }));
                    }
                    if (typeof (remainingPasswordAttempts) === 'number' && remainingPasswordAttempts < 1) {
                        this.logout();
                    }
                }
            }));
        });
    }
    /** handles the action on clicking the cancel button */
    cancel() {
        this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
            const url = val;
            this.userPreferencesService.getPreference('urlPath', false).subscribe(res => {
                const fullPath = `${url}#${res}`;
                this.router.navigate(['/externalRedirect', { externalUrl: fullPath }], {
                    skipLocationChange: true,
                });
            });
        });
    }
    /** handles the whether the view is responsive */
    getWindowSize() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 959) {
            this.responsiveView = true;
        }
        else {
            this.responsiveView = false;
        }
    }
    /** To unsubscribe to the subscription and event listener */
    ngOnDestroy() {
        if (this.prefSub) {
            this.prefSub.unsubscribe();
        }
        clearInterval(this.checkCookieInterval);
    }
    /* performs a redirect to logout */
    logout() {
        this.router.navigate(['logout']);
    }
    /** Check token cookie value status */
    checkForCookies() {
        this.checkCookieInterval = setInterval(() => {
            if (!this.cookieService.get('car-ses-tok')) {
                this.logout();
            }
        }, 500);
    }
}
