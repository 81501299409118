import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import * as i0 from "@angular/core";
import * as i1 from "./api-config.service";
import * as i2 from "@angular/common/http";
/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec {
    /** URL encode a key */
    encodeKey(key) {
        return encodeURIComponent(key);
    }
    /** URL encode a value */
    encodeValue(value) {
        return encodeURIComponent(value);
    }
    /** URL decode a key */
    decodeKey(key) {
        return decodeURIComponent(key);
    }
    /** URL decode a value */
    decodeValue(value) {
        return decodeURIComponent(value);
    }
}
/** @ignore */
const PARAMETER_CODEC = new ParameterCodec();
/**
 * Gets config data for API services
 */
export class BaseService {
    /**
     * base constructor
     * @param config API Config Service injection
     * @param http HTTP Client injection
     */
    constructor(config, http) {
        this.config = config;
        this.http = http;
    }
    /**
     * Returns the root url for API operations. If not set directly in this
     * service, will fallback to ApiConfiguration.rootUrl.
     */
    get rootUrl() {
        return this._rootUrl || this.config.rootUrl;
    }
    /**
     * Sets the root URL for API operations in this service.
     */
    set rootUrl(rootUrl) {
        this._rootUrl = rootUrl;
    }
    /**
     * Creates a new `HttpParams` with the correct codec
     */
    newParams() {
        return new HttpParams({
            encoder: PARAMETER_CODEC
        });
    }
}
BaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseService_Factory() { return new BaseService(i0.ɵɵinject(i1.ApiConfigService), i0.ɵɵinject(i2.HttpClient)); }, token: BaseService, providedIn: "root" });
