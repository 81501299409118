import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { copyright } from 'src/app/core/constants';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss']
})
export class PrivacyStatementComponent implements OnInit {
  /**property to print copyright string */
  readonly copyright = copyright;
  /** Used to hold logo config value */
  logoConfig: any;

  constructor(
    private readonly titleService: Title,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Privacy Notice');
    this.logoConfig = { logo: 'cartus', type: 'main' }
    this.spinner.show();
    setTimeout(() => { this.spinner.hide(); }, 2000 );
  }

}
