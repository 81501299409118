var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { TextEncoder } from 'text-encoding'; // Added for Edge browser compatiblity
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { Subscription } from 'rxjs';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { Title } from '@angular/platform-browser';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';
/** Base Login component */
export class LoginComponent {
    constructor(authSvc, spinner, fb, router, appConfig, moveSharedService, userPreferencesService, route, titleService, cookieLogoSvc, featureFlagService, _ngZone) {
        this.authSvc = authSvc;
        this.spinner = spinner;
        this.fb = fb;
        this.router = router;
        this.appConfig = appConfig;
        this.moveSharedService = moveSharedService;
        this.userPreferencesService = userPreferencesService;
        this.route = route;
        this.titleService = titleService;
        this.cookieLogoSvc = cookieLogoSvc;
        this.featureFlagService = featureFlagService;
        this._ngZone = _ngZone;
        /** Subscription property for subscribing and unsubscribing services */
        this.subscription = new Subscription();
        /*Available Sites*/
        this.authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices', 'domain'];
        /*To check redirected from authorised site or not*/
        this.authorisedSite = false;
        /*For the redirected URL */
        this.referredURL = '';
        /* Request Email Button Status*/
        this.requestEmailStatus = false;
        /* Okta session timeout */
        this.idleTimeoutMinutes = 55;
        /*Inactive user flag*/
        this.inactiveUser = false;
        /*Edge Chromium flag*/
        this.isEdgeChromium = false;
        /* Okta widget config */
        this.signInWidgetConfig = {};
        /**
       * Initializes the value
       * @param router Instance Router
       */
        /**Base Constructor for Login Component
         * @param authSvc Authentication Service to authenticate the user details
         */
        /** Login Form.  Contains email and password fields, which are both required. */
        this.loginForm = this.fb.group({
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')
            ])),
            password: ['', [Validators.required]]
        });
        this.templateString = {
            BNNER_TXT: `Technology That Moves You`,
            MOVEPRO360_TXT: `Welcome to MovePro360 ─ your centralized mobility hub and single source of truth ` +
                `for all your relocation and global talent mobility goals. Optimize move outcomes, anticipate ` +
                `needs, access predictive insights, and much more.`
        };
        /** Used to determine if the Google sign in is displayed */
        this.showGoogleButton = false;
        /** Used to hold the help links to display when the Google sign in is displayed */
        this.googleHelpLinks = ['Help'];
        /** Added for Edge Browser compatiblity */
        if (!window['TextEncoder']) {
            window['TextEncoder'] = TextEncoder;
        }
    }
    /** Login Component's Init Function */
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            // Deleting cookies on ngOnInit
            this.cookieLogoSvc.removeAllCookies();
            this.titleService.setTitle('Login');
            /* Region Google */
            const oktaUrl = String(this.appConfig.getConfig('oktaUrl'));
            const oktaId = String(this.appConfig.getConfig('oktaClientId'));
            const oktaRedirectUrl = String(this.appConfig.getConfig('oktaRedirectUri'));
            const oktaIdpRedirectUrl = String(this.appConfig.getConfig('oktaIdpRedirectUrl'));
            const oktaGoolgleIdpId = String(this.appConfig.getConfig('oktaGoolgleIdpId'));
            /* End Region */
            this.spinner.show();
            const googleIDPFeatureFlag = yield this.getGoogleIDPFeatureFlag();
            let routeData;
            this.routeSub = this.route.data.subscribe(v => routeData = v);
            (routeData['googleIdp']) && googleIDPFeatureFlag ? this.showGoogleButton = true : this.showGoogleButton = false;
            this.isEdgeChromium = this.detectEdgeChromium();
            // Initialize Okta widget
            this.signInWidgetConfig = {
                language: 'en',
                i18n: {
                    'en': {
                        'primaryauth.title': 'Enter your credentials to log in',
                        'primaryauth.submit': 'Log In'
                    }
                },
                features: {
                    idpDisovery: true,
                    rememberMe: false,
                    selfServiceUnlock: true,
                    multiOptionalFactorEnroll: true
                },
                baseUrl: oktaUrl.split('/oauth2')[0],
                clientId: oktaId,
                redirectUri: this.showGoogleButton ? oktaIdpRedirectUrl : oktaRedirectUrl,
                authParams: {
                    issuer: oktaUrl,
                    responseType: ['id_token', 'token'],
                    scopes: ['openid', 'email', 'profile']
                },
                helpLinks: {
                    help: '/#/contactUs',
                    forgotPassword: this.showGoogleButton ? '/#/contactUs' : null,
                    unlock: this.showGoogleButton ? '/#/contactUs' : null,
                },
                idps: this.showGoogleButton ? [
                    { type: 'GOOGLE', id: oktaGoolgleIdpId }
                ] : null
            };
            this.signInWidget = new OktaSignIn(this.signInWidgetConfig);
            /* Region Google IDP */
            this.signInWidget.on('ready', () => {
                if (this.showGoogleButton) {
                    const widgetFormEl = document.querySelector('form');
                    const widgetDividerEl = document.querySelector('.auth-divider');
                    const loginButton = document.querySelector('.social-auth-google-button');
                    const helpLinkEls = document.querySelectorAll('#help-links-container li');
                    if (widgetFormEl) {
                        widgetFormEl.style.display = 'none';
                        widgetFormEl.style.visibility = 'hidden';
                    }
                    if (widgetDividerEl) {
                        widgetDividerEl.style.display = 'none';
                        widgetDividerEl.style.visibility = 'hidden';
                    }
                    if (loginButton && !loginButton.hasAttribute('clickevent')) {
                        loginButton.setAttribute('clickevent', '');
                        loginButton.addEventListener('click', () => { this.message = null; });
                    }
                    if (helpLinkEls.length > 0) {
                        helpLinkEls.forEach((helpLinkEl) => {
                            const displayLink = this.googleHelpLinks.includes(helpLinkEl.textContent);
                            helpLinkEl.style.display = displayLink ? 'list-item' : 'none';
                            helpLinkEl.style.visibility = displayLink ? 'visible' : 'hidden';
                        });
                    }
                }
            });
            /* End Region */
            /* Region Okta Account Creation Error */
            this.signInWidget.on('afterError', (context, error) => {
                if (this.showGoogleButton
                    && context.controller && context.controller === 'primary-auth'
                    && error.name && error.name === 'OAUTH_ERROR'
                    && error.message && error.message === 'User creation was disabled.') {
                    this.message = 'Your Google account was not recognized.';
                }
            });
            /* End Region */
            this.signInWidget.renderEl({ el: '#widget' }, (res) => {
                this.onLoginResponse(res);
            }, (err) => {
                console.error(err);
            });
            // Handle message codes
            this.route.queryParams.subscribe(params => {
                if (params.code) {
                    switch (params.code) { // These codes are being kept in alignment with CartusOnline for consistency
                        case '4': {
                            this.message = 'You have been logged out due to inactivity.';
                            break;
                        }
                        case '5': {
                            this.message = 'Your account is no longer active.';
                            this.inactiveUser = true;
                            break;
                        }
                        case '14': {
                            this.message = 'Your session has expired.';
                            break;
                        }
                        default: {
                            this.message = null;
                        }
                    }
                }
            });
            // Set logo based on referrer
            this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
                this.referredURL = val ? val.replace(/(\/#|\/|#)$/, '') : '';
                const res = this.cookieLogoSvc.setLogo(this.referredURL);
                if (res) {
                    this.logoConfig = { logo: res, type: 'main' };
                }
            });
            this.moveSharedService.loginUserDetails.subscribe(loginUserDetails => {
                this.userDetails = loginUserDetails;
                if (!!this.userDetails && !!this.userDetails.userId && this.userDetails.userId.length > 0) {
                    switch (this.userDetails.product) {
                        case 'Alpha': // Non-Springboard Product Name is still returned by the API
                            this.logoConfig = { logo: 'mobilifyHR', type: 'main' };
                            break;
                        case 'MovePro': { // Non-Springboard Product Name is still returned by the API
                            if (this.userDetails.systemOfOrigin === 'Traditional') {
                                this.logoConfig = { logo: 'cartus', type: 'main' };
                            }
                            else {
                                this.logoConfig = { logo: 'movePro360', type: 'main' };
                            }
                            break;
                        }
                        case 'BenefitsBuilder': // Non-Springboard Product Name is still returned by the API
                            this.logoConfig = { logo: 'benefitsBuilder', type: 'main' };
                            break;
                        case 'SupplierPortal': // Non-Springboard Product Name is still returned by the API
                            this.logoConfig = { logo: 'supplierPortal', type: 'login' };
                            break;
                        case 'CompensationServices': // Non-Springboard Product Name is still returned by the API
                            this.logoConfig = { logo: 'compensationServices', type: 'login' };
                            break;
                        default:
                            break;
                    }
                }
            });
            // SSO check
            // No SSO in local mode
            if (!this.appConfig.getConfig('local')) {
                this.authSvc.trySso().then((tokenOrTokens) => {
                    if (tokenOrTokens && tokenOrTokens.tokens && tokenOrTokens.tokens.accessToken) {
                        // SSO authenticated
                        this.cookieLogoSvc.setSessionCookie(tokenOrTokens.tokens.accessToken);
                        this.subscription.add(this.authSvc
                            .resetPasswordAttempts({
                            "Authorization": tokenOrTokens.tokens.accessToken.accessToken
                        }).subscribe(() => {
                            // SSO authenticated - navigate to springboard
                            this._ngZone.run(() => {
                                this.router.navigate(['/springboard']);
                            });
                        }));
                    }
                    else {
                        this.spinner.hide(); // Not SSO authenticated
                    }
                });
            }
            else {
                this.spinner.hide(); // No SSO Check
            }
        });
    }
    /**
       * To get the GoogleIDP feature flag
       */
    getGoogleIDPFeatureFlag() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.featureFlagService.getgoogleIDPFeatureFlag();
        });
    }
    /** Component Angular destructor lifecycle hook */
    ngOnDestroy() {
        if (this.signInWidget) {
            this.signInWidget.remove();
        }
        if (this.prefSub) {
            this.prefSub.unsubscribe();
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }
    /** detect Edge Chromium for SP-341: Buttons and text indistingushable in High Contrast Mode */
    detectEdgeChromium() {
        const agent = window.navigator.userAgent.toLowerCase();
        return agent.indexOf('edg') > -1 ? true : false;
    }
    onLoginResponse(res) {
        if (res.status === 'FORGOT_PASSWORD_EMAIL_SENT') {
            // TBD
        }
        if (res.status === 'UNLOCK_ACCOUNT_EMAIL_SENT') {
            // TBD
        }
        if (res.status === 'PASSWORD_EXPIRED') { // Is this still needed?
            if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
                this.router.navigate(['/updatePassword']);
            }
        }
        if (res.status === 'LOCKED_OUT') { // Is this still needed?
            if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
                this.requestEmailStatus = true;
            }
        }
        if (res.status === 'SUCCESS') {
            // SSO Code for later - Do not remove
            // // OIDC
            // if (res.type === 'SESSION_STEP_UP') {
            //   console.log(res.user);
            //   console.log('Target resource url: ' + res.stepUp.url);
            // res.stepUp.finish();
            //   return;
            // } else {
            //   console.log(res.user);
            //   res.session.setCookieAndRedirect('https://acme.com/app');
            //   return;
            // }
            // // Not OIDC
            // console.log(res.claims);
            // this.signInWidget.tokenManager.add('my_id_token', res);
            // this.signInWidget.tokenManager.add('my_id_token', res[0]);
            // this.signInWidget.tokenManager.add('my_access_token', res[1]);
            // return;
            if (res && res.tokens && res.tokens.idToken && res.tokens.idToken.claims && res.tokens.idToken.claims.sub) {
                this.subscription.add(this.authSvc
                    .resetPasswordAttempts({
                    "Authorization": res.tokens.accessToken.accessToken
                })
                    .subscribe((() => {
                    this.cookieLogoSvc.setSessionCookie(res.tokens.accessToken);
                    // Store the tokens in the token manager so it can signout and revoke
                    this.authSvc.authClient.tokenManager.setTokens({ accessToken: res.tokens.accessToken,
                        idToken: res.tokens.idToken });
                    if (this.userDetails) {
                        let urls = [];
                        if (this.userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
                            if (this.userDetails.roleName === 'candidate') {
                                urls = this.appConfig.getConfig(this.authorizedLocations[2]).toString().split('|');
                            }
                            else if (this.userDetails.roleName === 'supplier-contact') {
                                urls = this.appConfig.getConfig(this.authorizedLocations[3]).toString().split('|');
                            }
                            else {
                                urls = this.appConfig.getConfig(this.authorizedLocations[1]).toString().split('|');
                            }
                        }
                        else if (this.userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
                            if (this.userDetails.systemOfOrigin === 'Traditional') {
                                urls = this.appConfig.getConfig(this.authorizedLocations[6]).toString().split('|');
                            }
                            else {
                                urls = this.appConfig.getConfig(this.authorizedLocations[0]).toString().split('|');
                            }
                        }
                        else if (this.userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
                            urls = this.appConfig.getConfig(this.authorizedLocations[4]).toString().split('|');
                        }
                        else if (this.userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API */
                            urls = this.appConfig.getConfig(this.authorizedLocations[5]).toString().split('|');
                        }
                        if (urls.length > 0) {
                            this.selfRegLoginURL = urls[0];
                            this.spinner.hide();
                            this.router.navigate(['/externalRedirect', { externalUrl: this.selfRegLoginURL }], {
                                skipLocationChange: true,
                            });
                        }
                        else { // Unrecognized userDetails
                            console.error('Unrecognized userDetails');
                        }
                    }
                    else { // No userDetails - navigate to springboard
                        this._ngZone.run(() => {
                            this.router.navigate(['/springboard']);
                        });
                    }
                })));
            }
        }
        this.spinner.hide();
    }
}
