import { Component, OnInit, OnDestroy, ViewEncapsulation, HostListener, NgZone } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from "@angular/platform-browser";
import { SpringboardApp } from "src/app/public/models/springboardApps.model";
import { SpringboardSetupService } from "src/app/core/services/springboard-setup.service";
import { Subscription, BehaviorSubject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { UserContext } from "src/app/core/models/user-context";
import { Contexts } from "src/app/core/models/contexts.model";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { AppConfigService } from "src/app/core/services/app-config.service";
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { Router } from "@angular/router";
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { SpringboardTimeoutService } from 'src/app/core/services/springboard-timeout.service';
import Timeout = NodeJS.Timeout;
import { SpringboardTimeoutComponent } from 'src/app/public/components/springboard/springboard-timeout/springboard-timeout.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';

@Component({
  selector: "app-springboard",
  templateUrl: "./springboard.component.html",
  styleUrls: ["./springboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: { class:'app-springboard' }
})

export class SpringboardComponent implements OnInit, OnDestroy {
  /** subscription for preference */
  prefSub: Subscription;
  /** referred */
  referred: boolean = false;
  /** referrer authorized */
  referrerAuthorized: boolean = false;
  /** referrer app name */
  referrerAppName: string;
  /** show referrer error */
  referrerError: boolean = false;
  /** referrerMessage variable to store the current referrer message */
  referrerMessage: string = "You have not been granted access to $appName.";
  /** referrerMessage subject to emit changes to referrerMessage */
  referrerMessage$ = new BehaviorSubject<string>(this.referrerMessage);
  /** url regex */
  urlRegex: RegExp = /(\/#|\/|#)$/
  /** show context selection */
  displayContextSelection: boolean = false;
  /** show context selection menu option */
  displayContextOption: boolean = true;
  /** show error */
  springboardError: boolean = false;
  /** show logged out */
  displayLoggedOut: boolean = false;
  /** is context selected */
  contextSelected: boolean = false;
  /** select account chosen in user-context component menu */
  contextChange: boolean = false;
  /** context selector dataSource */
  dataSource: MatTableDataSource<Contexts>;
  /**contextsList to store the retrieved contexts */
  contextsList: UserContext;
  /**selectedContext variable to store the selected context */
  selectedContext: Contexts;
  /** accessToken variable to hold access token contained in header */
  accessToken: string;
  /** partyId variable to hold the partyId from the chosen context */
  partyId: string;
  /** displayedColumns variable to store the displayed columns array */
  displayedColumns: string[] = ["company", "companyAddr"];
  /** applications variable to store the retrieved applications */
  applications: SpringboardApp[];
  /** show app selection */
  displayAppSelection: boolean = true;
  /** selectedApp variable to store the selected app */
  selectedApp: string = "";
  /** errorHeadings object to store error headings */
  errorHeadings = {
    GENRAL_ERROR: "Please contact the Cartus Help Desk",
    APP_NOT_AVAILABLE: "$appName is currently not available",
    APP_NOT_AVAILABLE_DEFAULT: "The selected application is currently not available",
    NO_APPS_ASSIGNED: "You currently have no applications assigned",
    LOGIN_FAILED: "The logon attempt failed"
  };
  /** errorHeaders variable to store the current error header */
  errorHeader: string = this.errorHeadings.GENRAL_ERROR;
  /** snackbar horizontal position */
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  /** snackbar vertical position */
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  /** sbErrorConfig object to hold snackBar config for errors */
  sbErrorConfig: any = {
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    data: '',
    duration: 10000
  };
  /** sbTimeoutConfig object to hold snackBar config for timeout */
  sbTimeoutConfig: any = {
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
  };
  /** logOutMessages object to store logged out messages */
  logOutMessages = {
    NORMAL: "You have been successfully logged out",
    TIMEOUT: "You have been logged out due to inactivity"
  }
  /** logOutMessage variable to store the current log out message */
  logOutMessage: string = this.logOutMessages.NORMAL;
  /** idle timeout minutes */
  idleTimeoutMinutes = 15;
  /** idle timeout cookie name */
  tokenCookieName = 'car-ses-tok';
  /** idle timeout cookie name */
  idleCookieName = 'lastAction';
  /** idle timeout keep-alive interval */
  pingInterval = 0;
  /** idle timeout poll interval */
  pollInterval = 1000;
  /** idle timeout check time */
  idleCheckSeconds = 60;
  /** idle timeout last mouse X */
  lastX = 0;
  /** idle timeout last mouse Y */
  lastY = 0;
  /** nodeJS timeout */
  lastTimeOut: Timeout;
  /** idle timeout warning */
  isIdleTimeoutWarning = false;
  /** initiate timeout warning  */
  idleTimeoutWarningInitiateMinutes:any;
  /** idle timeout warning minutes */
  idleTimeoutWarningMinutes = 13;
  /** session token refresh interval */
  refreshTokenInterval: any;
  /** session token refresh timeout minutes */
  refreshTokenTimeout = 9; // 3 chances to update in a 30 minute window
  /** app-context cookie name  */
  appContextCookieName = 'app-context'
  /**
   * Base constructor
   * @param spinner NgxSpinner
   * @param titleService: Title
   * @param springboardSetupSvc: SpringboardSetupService
   */
  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly titleService: Title,
    private readonly springboardSetupService: SpringboardSetupService,
    private readonly authSvc: AuthenticationService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly cookieLogoService: ManageCookieLogoService,
    private readonly appConfig: AppConfigService,
    private readonly springboardTimeoutService: SpringboardTimeoutService,
    private readonly userPreferencesService: UserPreferencesService,
    private _ngZone: NgZone,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.titleService.setTitle("Springboard");
    this.springboardTimeoutService.timeoutData$.pipe().subscribe(response => {
      if(response) {
        this._ngZone.run(() => {
          this._snackBar.openFromComponent(SpringboardTimeoutComponent, this.sbTimeoutConfig);
        });
      } else {
        if(this.isIdleTimeoutWarning === true){
          this._snackBar.dismiss();
        }
      }
    });
    this.refreshSessionInApp();
    this.startIdleTimeCountDown();
    this.authSvc.trySso().then((tokenOrTokens: any) => {
      if (tokenOrTokens && tokenOrTokens.tokens) {
        console.log("SSO authenticated");
        this.cookieLogoService.setSessionCookie(tokenOrTokens.tokens.accessToken);
        this.accessToken = tokenOrTokens.tokens.accessToken.accessToken;
        this.getUserContexts();
      } else {
        console.log("SSO failed");
        this.accessToken = this.cookieLogoService.getCookie('car-ses-tok')
        if (this.accessToken) {
          console.log("Token retrieved from service")
          this.getUserContexts();
        } else {
          console.log("No okta token stored")
          this.errorHeader = this.errorHeadings.LOGIN_FAILED;
          this.springboardError = true;
          this.spinner.hide();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.prefSub) {
      this.prefSub.unsubscribe();
    }
  }

  /** getUserContexts method to get all party and organization details associated with the partyId contained in the access token */
  getUserContexts() {
    this.springboardSetupService.getContexts(this.accessToken).toPromise()
    .then(async partyAndOrganizationDetails => {
      if (!partyAndOrganizationDetails || partyAndOrganizationDetails.length === 0) {
        this.errorHeader = this.errorHeadings.GENRAL_ERROR;
        this.springboardError = true;
        this.spinner.hide();
      } else {
        let partiesRoles: [{partyId?: string, roles?: string[]}] = [{}];
        const now = new Date();
        let userContext: UserContext = {count: 0, contexts: []};
        partyAndOrganizationDetails.forEach((item, index) => {
          if (item.party) {
            let contexts: Contexts = {};
            contexts.partyId = item.party._id;
            contexts.firstName = item.party.currentName && item.party.currentName.names && item.party.currentName.names.length > 0
                                 ? item.party.currentName.names[0] : null;
            contexts.lastName = item.party.currentName && item.party.currentName.names && item.party.currentName.names.length > 1
                                ? item.party.currentName.names[item.party.currentName.names.length - 1] : null;
            contexts.multiCurrencyFlag = false; //TODO: not in currently in payload, hardcoding to false as per discussion
            if (item.organizationDetails) {
              if (item.organizationDetails.entityName) {
                contexts.clientNo = item.organizationDetails.clientNumber;
                contexts.clientLegalName = item.organizationDetails.entityName;
              }
              if (item.organizationDetails.contactMechanisms && item.organizationDetails.contactMechanisms.length > 0) {
                const address = item.organizationDetails.contactMechanisms.filter(contactMechanism =>
                  contactMechanism.contactType === "address" &&
                  contactMechanism.usageType === 'business');
                if (address.length > 0) {
                  contexts.clientAddrLine1 = address[0].addressLines[0] ? address[0].addressLines[0] : null;
                  contexts.clientAddrLine2 = address[0].addressLines[1] ? address[0].addressLines[1] : null;
                  contexts.clientCityName = address[0].city ? address[0].city : null;
                  contexts.clientStProvName = address[0].state ? address[0].state : null;
                  contexts.clientCountryName = address[0].country ? address[0].country : null;
                  contexts.clientPostalCode = address[0].postalCode ? address[0].postalCode : null;
                }
             }
            }
            partiesRoles[index] = { partyId: item.party._id, roles: [] };
            if (item.party.roles) {
              item.party.roles.forEach(role => {
                if (!role.name) {
                  this.springboardError = true;
                  this.errorHeader = this.errorHeadings.GENRAL_ERROR;
                  this.spinner.hide();
                }
                if ((!role.fromDate || (role.fromDate && now > new Date(role.fromDate))) && (!role.toDate || (role.toDate && now < new Date(role.toDate)))) {
                  partiesRoles[index].roles.push(role.name);
                }
              });
              if (this.springboardError) return
            }
            userContext.contexts.push(contexts);
          }
        });
        userContext.count = userContext.contexts.length;
        this.contextsList = userContext;
        if (this.contextsList.count === 0) {
          this.errorHeader = this.errorHeadings.GENRAL_ERROR;
          this.springboardError = true;
          this.spinner.hide();
          return;
        }
        if (this.contextsList.count === 1) {
          this.selectContext(this.contextsList.contexts[0]);
          this.displayContextOption = false;
        } else {
          const partiesCount = partiesRoles.length;
          const transfereeCount = partiesRoles.filter(party => party.roles.includes('transferee')).length;
          const legacyTransfereeCount = partiesRoles.filter(party => party.roles.includes('legacy-transferee')).length;
          const clientContactCount = partiesRoles.filter(party => party.roles.includes('client-contact')).length;
          if (transfereeCount > 0) {
            const transferee = partiesRoles.find(party => party.roles.includes('transferee'));
            this.selectContext(this.contextsList.contexts.find(context => context.partyId === transferee.partyId));
            this.displayContextOption = false;
          } else if (clientContactCount === partiesCount && legacyTransfereeCount === 0) {
            this.dataSource = new MatTableDataSource<Contexts>(this.contextsList.contexts);
            this.displayContextSelection = true;
            this.spinner.hide();
          } else if (legacyTransfereeCount === partiesCount && clientContactCount === 0 ) {
            const legacyTransferee = partiesRoles.find(party => party.roles.includes('legacy-transferee'));
            this.selectContext(this.contextsList.contexts.find(context => context.partyId === legacyTransferee.partyId));
            this.displayContextOption = false;
          } else if (legacyTransfereeCount > 0 && clientContactCount > 0) {
            const clientContact = partiesRoles.find(party => party.roles.includes('client-contact'));
            this.selectContext(this.contextsList.contexts.find(context => context.partyId === clientContact.partyId));
            this.displayContextOption = false;
          } else {
            this.dataSource = new MatTableDataSource<Contexts>(this.contextsList.contexts);
            this.displayContextSelection = true;
            this.spinner.hide();
          }
        }
      }
    });
  }

  /** sortContexts method to sort the context selector table */
  sortContexts(sort: Sort): void {
    if (!sort.active || sort.direction === "") return;
    const data = this.contextsList.contexts.slice();
    const sortedData = [...data].sort((a, b) => {
      const isAsc: boolean = sort.direction === "asc";
      switch (sort.active) {
        case "clientLegalName":
          return this.compare(a.clientLegalName, b.clientLegalName, isAsc);
        case "clientAddrLine1":
          return this.compare(a.clientAddrLine1, b.clientAddrLine1, isAsc);
        default:
          return 0;
      }
    });
    this.contextsList.contexts = sortedData;
    this.dataSource = new MatTableDataSource<Contexts>(sortedData);
  }

  /** compare method used to compare values when sorting the context selector table */
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /** selectContext method to set the context chosen in the context selector */
  selectContext(context: Contexts) {
    this.cookieLogoService.setCookie(this.appContextCookieName, context.partyId, 1, '/', '.cartus.com', true);
    sessionStorage.setItem("car-ses-con", context.partyId);
    sessionStorage.setItem("UserContext", JSON.stringify(context));
    this.partyId = context.partyId;
    this.selectedContext = context;
    this.displayContextSelection = false;
    this.getApps(this.partyId);
  }

  /** getApps method to get the apps applicable to the selected context */
  async getApps(partyId: string) {
    this.spinner.show();
    await this.springboardSetupService
      .getApps(this.accessToken, partyId)
      .toPromise()
      .then(async springboardApps => {
        await this.findReferrer(springboardApps);
        if (springboardApps && springboardApps.Applications && springboardApps.Applications.length > 0) {
          if (this.referred) {
            if (this.referrerAuthorized) {
              this.applications = springboardApps.Applications;
              this.displayAppSelection = false;
              this.appSelectedHandler(this.referrerAppName);
            } else {
              this.referrerError = true
              this.applications = springboardApps.Applications;
              this.displayAppSelection = true;
            }
          } else {
            if (springboardApps.Applications.length === 1) {
              this.applications = springboardApps.Applications;
              this.displayAppSelection = false;
              this.appSelectedHandler(springboardApps.Applications[0].name);
            } else {
              this.applications = springboardApps.Applications;
              this.displayAppSelection = true;
            }
          }
        } else {
          this.applications = null;
          this.errorHeader = this.errorHeadings.NO_APPS_ASSIGNED;
          this.springboardError = true;
        }
      });
      this.spinner.hide();
  }

  //** handle the value emitted from child user-context component when 'select account' is chosen */
  contextChangeHandler(valueEmitted: boolean): void {
    if (valueEmitted) {
      this.applications = null;
      this.cookieLogoService.removeCookies([this.appContextCookieName]);
      sessionStorage.removeItem("car-ses-con");
      sessionStorage.removeItem("UserContext");
      this.selectedContext = null;
      this.displayContextSelection = true;
      this.contextSelected = false;
      this.springboardError = false;
      this.referrerError = false;
    }
  }

  //** handle the value emitted from child springboard-app when an app is selected and redirect if valid */
  async appSelectedHandler(valueEmitted: string): Promise<void> {
    this.spinner.show();
    const appName = this.applications.filter(app => app.name === valueEmitted)
    let coError: boolean = false;
    if (valueEmitted === "cartusOnline") {
      await this.springboardSetupService.getColToken(this.accessToken, this.partyId).toPromise()
      .then(async resp => {
        if(!resp || (resp && resp.status !== 200)) {
          this.spinner.hide();
          coError = true;
          this.sbErrorConfig.data = appName.length ?
          this.errorHeadings.APP_NOT_AVAILABLE.replace('$appName', appName[0].displayName) :
          this.errorHeadings.APP_NOT_AVAILABLE_DEFAULT;
          this.snackBar.openFromComponent(CustomSnackbarComponent, this.sbErrorConfig);
          // is cartusOnline the only app available to the user?
          if (this.applications.length === 1) {
            // display the app selector because the token call failed
            this.displayAppSelection = true;
          }
        }
      })
    }
    if (!coError) {
      const url = this.springboardSetupService.getAppUrlFromName(valueEmitted);
      if (url && url.indexOf("http") > -1) {
        this.titleService.setTitle("Loading...");
        this.router.navigate(["/externalRedirect", { externalUrl: url }], {
          skipLocationChange: true,
        });
      } else {
        this.sbErrorConfig.data = appName.length ?
        this.errorHeadings.APP_NOT_AVAILABLE.replace('$appName', appName[0].displayName) :
        this.errorHeadings.APP_NOT_AVAILABLE_DEFAULT;
        this.snackBar.openFromComponent(CustomSnackbarComponent, this.sbErrorConfig);
      }
      this.spinner.hide();
    }
  }

  //** handle the value emitted from child user-context when logout is selected */
  logoutHandler(valueEmitted: boolean): void {
    if (valueEmitted) {
      this.authSvc.signOut();
      this.onKeyPress = function(): void {};
      this.onMouseMove = function(): void {};
      clearInterval(this.refreshTokenInterval);
      if (this.lastTimeOut) clearTimeout(this.lastTimeOut);
      if(this.isIdleTimeoutWarning) this._snackBar.dismiss();
      sessionStorage.clear();
      this.cookieLogoService.removeCookies([this.tokenCookieName, this.idleCookieName]);
      this.displayContextSelection = false;
      this.applications = null;
      this.contextsList = null;
      this.selectedContext = null;
      this.springboardError = false;
      this.referrerError = false;
      this.displayLoggedOut = true;
    }
  }

  /** start refresh session interval */
  refreshSessionInApp() {
    this.refreshTokenInterval = setInterval(() => {
      this.refreshToken()
    }, 60000 * this.refreshTokenTimeout);
  }

  /** refresh token */
  refreshToken() {
    if (!this.springboardError) {
      this.authSvc.refreshSession().then((freshToken: any) => {
        if (freshToken) this.cookieLogoService.setSessionCookie(freshToken);
      }).catch(err => {
        console.log('error in freshToken :', err);
      });
    }
  }

  /** start idle timeout */
  startIdleTimeCountDown() {
    if (this.lastTimeOut) clearTimeout(this.lastTimeOut);
    this.refreshIdleCookie();
    this._ngZone.runOutsideAngular(() => {
      this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
    });
  }

  /** refresh idle cookie */
  refreshIdleCookie() {
    this.springboardTimeoutService.timeoutData.next(false);
    const currentTime = new Date().getTime();
    const idleExpireMs = currentTime + (this.idleTimeoutMinutes * 60000) - 500;
    this.idleTimeoutWarningInitiateMinutes = currentTime + (this.idleTimeoutWarningMinutes * 60000) - 500;
    this.isIdleTimeoutWarning = false;
    this.cookieLogoService.setCookie(this.idleCookieName, idleExpireMs.toString(), 1, '/', '.cartus.com', true);
  }

  /** check idle timeout status */
  checkIdle() {
    const idleExpireMs = parseInt(this.cookieLogoService.getCookie(this.idleCookieName), 10);
    const currTimeMs = new Date().getTime();
    if (!this.springboardError && currTimeMs > idleExpireMs) {
      this.logOutMessage = this.logOutMessages.TIMEOUT;
      this.logoutHandler(true);
    } else {
      if(!this.springboardError && !this.isIdleTimeoutWarning && currTimeMs >= this.idleTimeoutWarningInitiateMinutes) {
        this.isIdleTimeoutWarning = true;
        this.springboardTimeoutService.timeoutData.next(true);
      }
      this.pingInterval += this.pollInterval;
      if (this.pingInterval === 1000 * this.idleCheckSeconds) this.pingInterval = 0;
      this._ngZone.runOutsideAngular(() => {
        this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
      });
    }
  }

  /** listen for mouse events */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    if (e.pageX !== this.lastX || e.pageY !== this.lastY) {
      this.lastX = e.pageX;
      this.lastY = e.pageY;
      this.refreshIdleCookie();
    }
  }

  /** listen for keypress events */
  @HostListener('document:keypress', ['$event'])
  onKeyPress() {
    this.refreshIdleCookie();
  }

  /** establish if an application referred to login */
  async findReferrer(springboardApps: any): Promise<void> {
    const applicationList = [
      {
        name: 'movepro360',
        friendlyName: 'MovePro360'
      },
      {
        name: 'mobilifyhr',
        friendlyName: 'Mobilify HR',
      },
      {
        name: 'supplierportal',
        friendlyName: 'Supplier Portal'
      },
      {
        name: 'benefitsbuilder',
        friendlyName: 'Benefits Builder'
      },
      {
        name: 'compensationServices',
        friendlyName: 'Compensation Services'
      }
    ];
    let referredURL: string;
    this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(referrer => {
      referredURL = referrer ? referrer : undefined;
    });
    if (referredURL) {
      this.referred = true;
      if (springboardApps && springboardApps.Applications) {
        for (const app of springboardApps.Applications) {
          if (!this.referrerAppName && app.name) {
            const URL = this.springboardSetupService.getAppUrlFromName(app.name);
            if (URL.replace(this.urlRegex, '').toLowerCase() === referredURL.replace(this.urlRegex, '').toLowerCase()) {
              this.referrerAppName = app.name;
              this.referrerAuthorized = true;
            }
          }
        }
        if (!this.referrerAuthorized) {
          const strippedUrl = referredURL.replace(/^https?:\/\//, '').replace(/\..*$/, '');
          const determinedApp = applicationList.filter(loc => strippedUrl.includes(loc.name));
          const friendlyAppName = determinedApp.length > 0 ? determinedApp[0].friendlyName : referredURL;
          this.referrerMessage$.next(this.referrerMessage.replace('$appName', friendlyAppName));
        }
      }
    }
  } 

}
