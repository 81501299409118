<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<form fxLayout="row" [formGroup]="regValidationForm" fxFlex> 
        <div class="otp-section panel-bg" fxFlex>
            <div class="otp-header">{{ showEmail ? templateString.emailPlaceholderText : templateString.PHONE_PLACEHOLDER_TEXT }}</div>
            <div [ngClass]="{ 'otp-email': showEmail, 'otp-phone-number': !showEmail }" fxLayout.lt-lg="column" fxLayout.gt-md="row" fxLayoutAlign.gt-md="space-between center">
                <p *ngIf="!showEmail" class="no-height" fxFlex.gt-md="1 0 15em">
                    <span class="otp-hidden-char">{{phoneNumberHiddenChar}}</span>
                    <span class="phone-last-digits">{{PhoneNumberLastChars}}</span>
                </p>
                <p *ngIf="showEmail" class="no-height" fxFlex.lg="1 0 20em" fxFlex.xl="1 0 15em" >
                        <span class="masked-email">{{maskedEmail}}</span>
                </p>
                <p class="otp-text clean" [ngClass]="{ 'email-otp-text': showEmail }">
                    <span *ngIf="!showEmail">{{contentTextPrefix}}</span> 
                    <span *ngIf="showEmail">{{contentEmailPrefix}}</span>
                    <span *ngIf = "isCandidateorTransferee"> {{userDetails.clientName}}</span>
                    <span *ngIf = "!isCandidateorTransferee"> {{clientContactName}}</span>
                    <span *ngIf="showEmail">.</span>
                    <span *ngIf="!showEmail">{{contentTextPostfix}}</span>
                </p>
            </div>
            <div *ngIf="otpToggle" class="otp-toggle-text no-position link-style"><a class="toggle-otp" (click)=toggleOTPMethod()>Use {{ showEmail ? 'Phone Number' : 'Email Address' }}</a></div>
                <div class="btn-align-center" *ngIf="showEmail && !otpEmailSent || !showEmail && !otpPhoneSent">
                    <div class="login-contain-btn send-btn">
                        <button type="button" (click)="sendOTP()" id="sendOTP">
                            {{templateString.SEND_CODE_BTN}}
                        </button>
                    </div>
                </div>
                <div class="otp-code-container" *ngIf="showEmail && otpEmailSent || !showEmail && otpPhoneSent">
                    <p class="clean otp-code-text">
                        <span>{{otpTextPrefix}}</span>
                        <span class="link-style">
                            <a (click)="resendOTP()" id="resendOTP">{{templateString.RESEND_CODE}}</a>
                        </span>
                        <span>{{optTextPostfix}}</span>
                    </p>
                    <div class="mat-form-input submit-otp">
                        <mat-form-field appearance="fill">
                            <mat-label>Security Code</mat-label>
                            <input matInput placeholder="SecurityCode" formControlName="otp" (focus)="checkOTP()">
                            <mat-error>{{ getErrorMessage('OTP') }}</mat-error>
                            <mat-error *ngIf="regValidationForm.controls['otp'].hasError('invalidOTP')">
                                {{errorString.INVALID_OTP}}
                             </mat-error>
                             <mat-error *ngIf="regValidationForm.controls['otp'].hasError('exceededAttempts')">
                                {{errorString.OTP_ATTEMPTS_EXCEEDED}}
                             </mat-error>
                        </mat-form-field>
                        <div class="login-contain-btn btn-align-center">
                            <button type="submit" [disabled]="!regValidationForm.valid && !isContinueBtnEnable" (click)="validateOTP()" id="submitOTP">
                                {{templateString.CONTINUE_BTN}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    </form>