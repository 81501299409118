import { Injectable } from '@angular/core';
import { ObservableInput, throwError, EMPTY } from 'rxjs';
import { RemoteLoggingService } from './remote-logging.service';
import { Router } from '@angular/router';

/**
 * HTTP response error handler.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {

  /**
   * base constructor
   * @param logger remote logger service injector
   */
  constructor(private readonly logger: RemoteLoggingService, private readonly router: Router) { }
  /**
   * Handle Http response error.  redirecting to logout on occasion or just logging the error with the remote logging service.
   * @param actionName method/action from which the error occurred
   * @param returnError whether to return the error, required for redirectToLogout
   */
  handleHttpErrorResponse(actionName: string, returnError: boolean = true) {
    return (error: any, caught: any): ObservableInput<{}> | ObservableInput<any> => {
      let errorMsg = '';

      if (error.status === 401) {
        errorMsg = `While ${actionName} user was noted as unauthenticated and logged out of the system. ${error.error.message}`;
        console.warn(`While ${actionName} user was noted as unauthenticated and logged out of the system`, `${error.error.message}`);
        this.redirectToLogout();
      } else if (error.error && error.error.message) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMsg = `An error occurred while ${actionName}: ${error.error.message}`;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMsg = `Backend returned code ${error.status}, body was: ${JSON.stringify(error.error)}`;
      }
      // return an observable with a user-facing error message
      const httpErr = new Error(errorMsg);
      this.logger.logError(httpErr);
      if (returnError) {
        return throwError(httpErr);
      } else {
        return EMPTY;
      }
    };
  }

  /**
   * redirects the system to the logout page
   */
  redirectToLogout() {
    // TODO: maybe - change logout URL to something other than the COL logout view
    setTimeout(() => this.router.navigate(['/logout'], { queryParams: { code: 4 } }), 50);
  }
}
