import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SessionTimeoutComponent implements OnInit {


  /**
   * Base constructor
   * @param _snackRef MatSnackBarRef
   */

  constructor(
      private readonly _snackRef: MatSnackBarRef<SessionTimeoutComponent>,
      @Inject(MAT_SNACK_BAR_DATA) public data: any,
) { }

  ngOnInit() {}

  /** To Dismiss snackbar */
  dismiss() {
    this._snackRef.dismissWithAction();
  }

}
