import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input } from '@angular/core';
import { AppConfigService } from '../../../core/services/app-config.service';
import { Router } from '@angular/router';
import { Contexts } from '../../../core/models/contexts.model';
import { UserContext } from "src/app/core/models/user-context";
import {A11yModule, LiveAnnouncer} from '@angular/cdk/a11y';

/**user context component to display user context details */
@Component({
  selector: 'app-user-context',
  templateUrl: './user-context.component.html',
  styleUrls: ['./user-context.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserContextComponent implements OnInit {
  @Input() contextsList: UserContext;
  @Input() displayContextOption: boolean;
  @Input() selectedContext: Contexts;
  @Output() contextChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loggedOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** user context constructor method */
  constructor(
    private readonly appConfig: AppConfigService,
    private readonly router: Router,
    private liveAnnouncer: LiveAnnouncer,
  ) {}

  /** Base init method */
  ngOnInit() {}

  // /** SSO logout - invalidate token */
  // logout(): void {
  //   sessionStorage.clear();
  //   //Todo - invalidate okta token
  //   //Todo - display logged message?
  // }

  changeContext() {
    this.contextChange.emit(true);
  }

  logout() {
    this.loggedOut.emit(true);
  }

  announceContext(): void {
   this.liveAnnouncer.announce(
   this.selectedContext.firstName + ' ' +
   this.selectedContext.clientLegalName + ' ' +
   this.selectedContext.clientNo);
  }

}
