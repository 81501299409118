import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';

/** user service to post details of login to the server
 * header
 */
@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    constructor(
        protected baseClient: BaseClientService,
        private readonly logSvc: RemoteLoggingService,
    ) { }

    googleIDPFeatureFlag$ = new BehaviorSubject<string>(null);

    async getgoogleIDPFeatureFlag(): Promise<boolean> {
        if (!this.googleIDPFeatureFlag$.value) {
            const response = await this.getFeatureFlag('enable-google-idp').toPromise();
            this.googleIDPFeatureFlag$.next(response);
        }
        return Promise.resolve(this.googleIDPFeatureFlag$.value && this.googleIDPFeatureFlag$.value.toString() === 'true');
    }
    
    getFeatureFlag(name: string): Observable<any> {
        return this.baseClient.getById<boolean>('/featureFlag/' + name).pipe(
            map(r => r.body),
            catchError((err, source) => {
                const empty: boolean = null;
                this.logSvc.logError(err);
                return of(empty);
            })
        );
    }
}
