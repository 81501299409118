import { of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
/** user service to post details of login to the server
 * header
 */
export class SelfRegistrationSetupService {
    constructor(baseClient, logSvc) {
        this.baseClient = baseClient;
        this.logSvc = logSvc;
    }
    registerUser(request) {
        return this.baseClient.postUnauthorizedHandler('/user/register', request).pipe(map(r => r.body), catchError((err, source) => {
            return of(err);
        }));
    }
    getUser(userId) {
        return this.baseClient.getById('/user/' + userId).pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.logSvc.logError(err);
            return of(empty);
        }));
    }
    validateRegistration(userId) {
        return this.baseClient.getNoErrorHandler(`/user/validateRegistration/${userId}`).pipe(map(r => r.body), catchError((err, source) => {
            this.logSvc.logError(err);
            return of(err);
        }));
    }
}
SelfRegistrationSetupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SelfRegistrationSetupService_Factory() { return new SelfRegistrationSetupService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService)); }, token: SelfRegistrationSetupService, providedIn: "root" });
