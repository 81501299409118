import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "./app-config.service";
/**
 * Gets the global API services configuration
 */
export class ApiConfigService {
    /**
     * Initialize the rootURL
     */
    constructor(config) {
        this.config = config;
        const apiConfig = this.config.getConfig('api');
        this.rootUrl = `${apiConfig.protocol}://${apiConfig.host}:${apiConfig.port}${apiConfig.base_url}`;
    }
}
ApiConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiConfigService_Factory() { return new ApiConfigService(i0.ɵɵinject(i1.AppConfigService)); }, token: ApiConfigService, providedIn: "root" });
