<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<app-header [redirect]="false" [logoConfig]="logoConfig" role="navigation"></app-header>
<div class="privacy-notice">
    <div class="main-content">
        <div class="heading">
            Privacy Notice
        </div>
        <div class="lastModified">
            Last modified 5 October, 2022
        </div>
        <div class="text">
            We at Cartus (collectively, “Cartus”, “we”, “us”, or “our”) recognize the importance of
            protecting ‎the privacy of personal information (as defined below in Section 1) collected by us.
            This ‎Privacy Notice describes how Cartus collects, uses, discloses, retains, and transfers
            Personal ‎Information in performing relocation and other services (the “Services”).
            It also describes ‎your choices regarding use, access and correction of your Personal Information.
            Personal ‎Information may be requested/collected to enable the delivery of our services, to operate our
            ‎business and to make the experience of using our websites faster and more effective.‎
        </div>
        <div class="text">
            By using our website(s), you consent to the terms of this Privacy Notice and to Cartus’
            processing ‎of Personal Information for the purposes stated in this Privacy Notice, as well
            as those explained where Cartus ‎collects Personal Information on the web.
            IF YOU DO NOT AGREE TO THIS PRIVACY ‎NOTICE, YOU CANNOT USE OUR SERVICES. ‎
        </div>

                <div class="sub-heading-privacy">
                    OVERVIEW OF OUR COLLECTION AND USE OF PERSONAL INFORMATION
                </div>
                <div class="text">
                    We think transparency is important. In this overview, we summarize the Personal
                    Information we collect and how we use it, which is further explained in our Privacy Notice
                    below. Keep in mind that the actual Personal Information we collect and how we use it varies
                    depending upon the nature of our relationship and interactions with you. Also, in some cases
                    (such as where required by law), we ask for your consent or give you certain choices prior to
                    collecting or using certain Personal Information.‎
                </div>

            <div class="text">
                    <span class="decorated">
                        Categories of Personal Information Collected.
                    </span>
                    Generally, we collect the following types of Personal Information:
                <ul>
                    <li>
                        <div class="privacy-overview-subcont">
                            <span>Identifiers</span>:
                            includes direct identifiers such as a real ‎name, alias, address, user ID,
                            username, account number; phone number, unique ‎personal identifier,
                            online identifier, IP address, ‎app ID, email address, account name,
                            social security ‎number, social insurance number, driver's license ‎number,
                            passport number, or other similar identifiers.‎
                        </div>
                    </li>
                    <li>
                        <div class="privacy-overview-subcont">
                            <span>Customer records</span>:
                            includes Personal Information that individuals provide us in order to
                            obtain our products and services, such as ‎name, account name and number,
                            signature, contact information, employment information, social security
                            number, social insurance number, passport number, driver's license and
                            other government identifiers and financial ‎information.  For example,
                            this may include information collected when an individual registers for
                            our services.
                           </div>
                    </li>
                    <li>
                        <div class="privacy-overview-subcont">
                            <span>Commercial information</span>:
                            includes records of personal property, products or ‎services purchased, ‎obtained, or
                            considered, or
                            other purchasing or use ‎histories or tendencies.‎
                        </div>
                    </li>
                    <li>
                        <div class="privacy-overview-subcont">
                            <span>Usage Data</span>:
                            includes browsing history, ‎clickstream data, search history, and information ‎regarding a individual's
                            interaction with an internet ‎website, application, or ‎advertisement.‎‎
                        </div>
                    </li>
                    <li>
                        <div class="privacy-overview-subcont">
                            <span>Geolocation data</span>:
                            includes precise location information about a particular individual or ‎device.‎
                        </div>
                    </li>
                    <li>
                        <div class="privacy-overview-subcont">
                            <span>Audio, video and other electronic data</span>:
                            includes audio, electronic, visual or similar information ‎such as thermal screenings and CCTV footage
                            (e.g., collected from visitors to our offices), photographs, and call ‎recordings
                            (e.g., of customer support calls).
                        </div>
                    </li>
                    <li>
                        <div class="privacy-overview-subcont">
                            <span>Professional Information</span>:
                            includes professional or employment-related information.‎
                        </div>
                    </li>
                    <li>
                        <div class="privacy-overview-subcont">
                            <span>Education information</span>:
                            educational history and records.
                        </div>
                    </li>
                    <li>
                        <div class="privacy-overview-subcont privacy-overview-last-subcont">
                            <span>Inferences</span>:
                            inferences drawn from and of the above. Personal Information used to create a profile reflecting an individual’s
                            preferences, behavior, or other characteristics.
                        </div>
                    </li>
                </ul>
            </div>

        <div class="sub-heading-privacy margin-top">
            1. SCOPE OF OUR PRIVACY NOTICE
        </div>
        <div class="text">
            This Notice applies to our collection, use, disclosure, and other processing of Personal Information related to:
            <ul>
                <li>
                    the use of our Services that display or link to this Notice;
                </li>
                <li>former, current, and prospective clients and customers; and</li>
                <li>other individuals who use our Services, whose Personal Information we receive related to the Services, or who
                    otherwise interact or communicate with us related to our Services</li>
            </ul>
        </div>
        <div class="text">
        Our collection, use, disclosure, and processing of Personal Information about individuals will vary depending upon the
        circumstances. This Privacy Notice is intended to describe our overall privacy and data protection practices.
        In some cases, different or additional notices about our data collection and processing practices may be provided and
        apply to our processing of certain Personal Information
        </div>
        <div class="text">
            <span class="highlight">Personal Information.</span> In this Privacy Notice, our use of the term "Personal Information" includes other similar
        terms under applicable privacy laws—such as "personal data" and "personally identifiable information." In general, Personal
        Information includes any information that identifies, relates to, describes, or is reasonably capable of being associated,
        linked, or linkable with a particular individual.
        </div>
        <div class="text">
            <span class="highlight">Not covered by this Notice.</span> This Privacy Notice does not apply to job applicants and candidates who apply for
            employment with us, or to our employees and non-employee workers.
        </div>
        <div class="sub-heading-privacy margin-top">
            2. OUR COLLECTION OF PERSONAL INFORMATION
        </div>
         <div class="text">
            Generally, we collect your Personal Information on a voluntary basis. However, if you decline to provide certain Personal
            Information that is marked mandatory, you may not be able to access certain Services or we may be unable to fully respond to
            your inquiry.
         </div>
         <div class="text">
            We may collect Personal Information directly from individuals, automatically related to the use of the Services, and in some cases,
            from third parties (such as a relocating employee’s employer and operators of certain third party services that we use).
         </div>
        <div class="text">
            <span class="highlight">Information we collect directly.</span> We collect information that you provide to us, such as
            <ul>
                <li>when you create an account, provide contact information, and send other Personal Information to us;</li>
                <li>
                    when you complete or submit a form through our Services, for example, in CartusOnline or via "Contact Us" or
                    "More Information" forms;
                </li>
                <li>
                    when you participate in surveys, submit orders or requests, or register for or use our Services, or otherwise contact us;
                </li>
                <li>information you submit or provide such as information related to the purchase or sale of real estate.</li>
            </ul>
        </div>
        <div class="text">
            <span class="highlight">Information we collect from third parties.</span> We may collect Personal Information about you from third party sources,
            such as a relocating employee’s employer and from other third parties. For example, without limitation, if you choose to link,
            create, or log in to your Services account with a social media service (e.g., Twitter, Facebook, Instagram, etc.), or if you
            engage with a separate app or website that communicates with the Services, we may receive Personal Information about you or your
            connections from that service. In many cases you can control what Personal Information you share with us through privacy settings
            available on those third-party social media services.
        </div>
        <div class="sub-heading-privacy margin-top">
            3. LEGAL BASES FOR OUR PROCESSING OF PERSONAL INFORMATION
        </div>
        <div class="text">
            Certain laws, including the EU General Data Protection Regulation ("GDPR"), require that we inform applicable individuals of
            the legal bases for our use and other processing of Personal Information.
        </div>
        <div class="text">
            Pursuant to the GDPR (and other relevant laws), we process Personal Information for the following legal bases:
            <ul>
                <li>
                    <div>
                        <span class="decorated">
                            Performance of contract:
                        </span>
                         as necessary to enter into or carry out the performance of a contract with you.
                    </div>
                </li>
                <li>
                    <div>
                        <span class="decorated">Compliance with laws:</span> for compliance with legal obligations and/or defense against legal claims,
                        including those in the area of labor and employment law, social security, and data protection, tax,
                        and corporate compliance laws.
                    </div>
                </li>
                <li>
                    <div>
                        <span class="decorated">Our or our clients’ legitimate interests:</span> in furtherance of our or our clients’
                        legitimate business interests including:
                        <ul>
                            <li>
                                Performance of contracts, including for relocation services
                            </li>
                            <li>Implementation and operation of global support (e.g., IT) services for our business operations</li>
                            <li>Customer relationship management and improving our Services, including marketing and analytics</li>
                            <li>Fraud prevention, including misuse of company IT systems or money laundering</li>
                            <li>Physical, IT, and network perimeter security</li>
                            <li>Internal investigations</li>
                            <li>Mergers, acquisitions, and reorganization, and other business transactions</li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div class="pT10">
                        <span class="decorated">With your consent:</span> where we need and obtain your consent (the GDPR (where it applies))
                        and other applicable laws give you the right to withdraw your consent, which you can do at any time by contacting us using
                        the details at the end of this Privacy Notice. In some jurisdictions, your use of the Services may be taken as implied
                        consent to the collection and processing of Personal Information as outlined in this Privacy Notice.
                    </div>
                </li>
            </ul>
            <div class="mT12">
            In addition, we may process your Personal Information where necessary to protect the vital interests of any individual.
        </div>
        </div>
        <div class="sub-heading-privacy margin-top">
            4. USE OF PERSONAL INFORMATION
        </div>
        <div class="text">
            We process your Personal Information described above as necessary for Cartus to provide ‎relocation services under the
            terms of its contract with your employer or ‎membership group consistent with the legitimate interests of your employer
            or membership ‎group (i.e., our corporate or government entity client). If you provide us with sensitive Personal ‎Information,
            we will process it based on your consent. In general, we collect, use, disclose, retain ‎and transfer Personal Information
            <span class="highlight">for
            the purposes of managing and facilitating your move from ‎one location to a new location</span> (the <span class="highlight">"Relocation Purpose"</span>), including
            the following to the extent ‎authorized by your employer or membership group: (a) arranging for your physical move and the ‎
            storage of your property; (b) tracking and processing expense reimbursements and other ‎benefits, such as lump sum payments;
            (c) home sales, purchases, and rentals, including ‎financing arrangements; (d) explaining various employer-sponsored benefits;
            (e) immigration ‎and other customs related purposes; (f) referring you to third parties who provide cultural ‎assistance
            and/or language training; and (g) other activities relating to the delivery and ‎improvement of relocation services.
            Any access to your Personal Information is restricted to ‎those individuals that have a need to know to fulfill their job
            responsibilities. We will not use ‎Personal Information for any other purpose incompatible with the Relocation Purpose
            outlined in ‎this Privacy Notice, unless it is required or authorized by laws that apply to Cartus or otherwise ‎authorized by you.
        </div>
        <div class="text">
            In addition to the Relocation Purpose we also use Personal Information for the purposes set forth below. Where GDPR or
            other relevant laws apply, we have set forth the legal bases for such processing.
            <ul>
                <li>
                    <div>
                        <span class="decorated">
                    Providing support and services:</span> including, for example, to send or facilitate communications with you,
                    your employer or membership group, to provide the Services, provide products and services you or your employer
                    request (and send related information), operate our Services; to communicate with you about your access to and use
                    of our Services; to respond to your inquiries; to provide troubleshooting, fulfill your requests and provide
                    technical support; and for other customer service and support purposes. <em>(Legal basis: performance of our contract
                    with you or your employer or membership group; and our and our clients’ legitimate interests)</em></div>
                </li>
                <li>
                    <div>
                        <span class="decorated">Analyzing and improving our business:</span> including to better understand how users access and use our Services, to evaluate and
                        improve our Services and business operations, and to develop new features, offerings, and services; to conduct surveys, and
                        other evaluations, such as customer satisfaction surveys; and for other research and analytical purposes. <em>(Legal basis:
                        our legitimate interests)</em>
                    </div>
                </li>
                <li>
                    <div>
                    <span class="decorated">Personalizing content and experiences:</span> including to provide or recommend features, content, social connections,
                    and referrals; tailor content we send or display on our Services; to offer location customization and personalized
                    help and instructions; and to otherwise personalize your experiences. <em>(Legal basis: our legitimate interests and/or
                    with your consent)</em></div>
                </li>
                <li>
                    <div><span class="decorated">Advertising, marketing and promotional purposes:</span> including to evaluate, measure, and improve the effectiveness of
                    our ad campaigns; to send you newsletters, offers, or other information we think may interest you; and to contact
                    you about our Services or information we think may interest you. <em>(Legal basis: our legitimate interests and/or with
                    your consent)</em></div>
                </li>
                <li>
                    <div><span class="decorated">Securing and protecting our business:</span> including to protect and secure our
                        business operations, assets, Services, network and information and technology resources; to investigate,
                        prevent, detect and take action regarding fraud, unauthorized access, situations involving potential threats
                        to the rights or safety of any person or third party, or other unauthorized activities or misconduct.
                         <em>(Legal basis: our legitimate interests and/or compliance with laws)</em></div>
                </li>
                <li>
                <div><span class="decorated">Defending our legal rights:</span> including to manage and respond to actual and potential
                    legal disputes and claims, and to otherwise establish, defend or protect our rights or interests, including in
                    the context of anticipated or actual litigation with third parties. <em>(Legal basis: our legitimate interests
                    and/or compliance with laws)</em></div>
                </li>
                <li>
                    <div><span class="decorated">Auditing, reporting, corporate governance, and internal operations:</span> including
                        relating to financial, tax and accounting audits; audits and assessments of our operations, privacy, security
                        and financial controls, risk, and compliance with legal obligations; our general business, accounting, record
                        keeping and legal functions; and related to any actual or contemplated merger, acquisition, asset sale or
                        transfer, financing, bankruptcy or restructuring of all or part of our business. <em>(Legal basis: our legitimate
                        interests and/or compliance with laws)</em></div>
                    </li>
                    <li>
                        <div><span class="decorated">Complying with legal obligations:</span> including to comply with the law, our
                            legal obligations and legal process, such warrants, subpoenas, court orders, and regulatory or law
                            enforcement requests. <em>(Legal basis: our legitimate interests and/or compliance with laws)</em></div>
                        </li>

            </ul>
        </div>
        <div class="text">
           <span class="highlight">Aggregate and de-identified information.</span>
               We may de-identify Personal Information and
           create anonymous and aggregated data sets and reports in order to assess, improve and develop our business, products and
           services, prepare benchmarking reports on our industry and for other research, marketing and analytics purposes.
        </div>
       <div class="sub-heading-privacy margin-top">
        5. DISCLOSURE OF PERSONAL INFORMATION
       </div>
       <div class="text">
        We may disclose the Personal Information that we collect about you as set forth below or as otherwise described at
        the time of collection or sharing.
        <ul>
            <li>
                <div><span class="decorated">Service providers:</span> We may disclose Personal Information with third-party service
                    providers who use this information to perform services for us, such as hosting providers, auditors, advisors,
                    consultants, and customer service and support providers. </div>
            </li>
            <li>
                <div><span class="decorated">Subsidiaries and affiliates:</span> We may share your Personal Information with our
                    affiliated companies (i.e., companies under common ownership, control or management with us) - use will be subject
                    to this Privacy Notice.</div>
            </li>
            <li>
                <div><span class="decorated">Third parties.</span> We may disclose Personal Information to third parties in order to provide
                    our Services, facilitate your requests, or with your acknowledgement and consent, including with:
                    <ul>
                        <li>independent real estate professionals, moving services providers, immigration and visa service
                            providers to enable them to provide services you request through the Services.</li>
                        <li>other third parties to provide you a service you or your employer requested.</li>
                        <li>the public if you submit information, such as comments, social media posts, or other features viewable
                            by the public.</li>
                        <li>third parties with whom you choose to let us share information, for example other apps or websites that
                            integrate with the Services, or those with Services with which we integrate.</li>
                        <li>third parties we work with to provide you with more personalized content, and to track the effectiveness
                            of campaigns and our Services.</li>
                    </ul>
                </div>
                </li>
                <li>
                    <div><span class="decorated">Business transfers:</span> We may disclose or transfer Personal Information as part of
                        any actual or contemplated merger, sale, and transfer of our assets, acquisition, financing or restructuring of
                        all or part of our business, bankruptcy or similar event, including related to due diligence conducted prior to
                        such event where permitted by law.</div>
                </li>
                <li>
                    <div><span class="decorated">Legally required:</span> We may disclose Personal Information if we are required to do
                        so by law (e.g., to law enforcement, courts or others, e.g., in response to a subpoena or court order).
                    </div>
                </li>
                <li>
                    <div><span class="decorated">Protect our rights:</span> We may disclose Personal Information where we believe it
                        necessary to respond to claims asserted against us or, comply with legal process (e.g., subpoenas or warrants),
                        enforce or administer our agreements and terms, for fraud prevention, risk assessment, investigation, and to
                        protect the rights, property, or safety of us, our clients and customers or others.
                    </div>
                    <div>
                        <br />
                        <span class="highlight">Anonymized and Aggregated Data.</span> We may share aggregate or de-identified information with third parties
                        for research, marketing, advertising, analytics, and other purposes.
                    </div>
                </li>
        </ul>
       </div>
       <div class="sub-heading-privacy margin-top">
        6. COOKIES, TRACKING AND ANALYTICS
       </div>
       <div class="text">
        Our Services may use first party and third-party cookies, pixel tags, plugins, and other tools to gather device, usage and
        browsing information when users visit our websites. For instance, when you visit our websites, our server may record your IP address
        (and associated location information) and other information such as the type of your internet browser, your Media Access Control
        (MAC) address, computer type (Windows or Macintosh), screen resolution, operating system name and version, device manufacturer and
        model, language, and the pages you view and links you select on the Services, as well as date and time stamps associates with your
        activities on our Services.
       </div>

       <div class="text">
        We use the information for security purposes, to facilitate navigation, to personalize and improve your experience while using the
        Services, to improve and measure our advertising campaigns and to better reach users. We also gather statistical information about
        use of the Services in order to continually improve their design and functionality, understand how they are used and assist us with
        resolving questions regarding them.
       </div>
       <div class="text">
        <span class="highlight">Cookies.</span> Cookies are small text files that a website transfers to your hard drive to store and
        sometimes collect information about your usage of websites, such as time spent on the websites, pages visited, language preferences,
        and other anonymous traffic data. You can control the way in which cookies are used by altering your browser settings. You may refuse
        to accept cookies by activating the setting on your browser that allows you to reject cookies. However, if you select such a
        setting, this may affect the functioning of our sites. Unless you have adjusted your browser setting so that it will refuse cookies,
        our system will issue cookies when you access or log on to our Service.
        <ul>
            <li><div class="pB10"><em>Essential cookies</em> are essential, as they enable you to move around the Service and use its features.
            Without these cookies we could not, for example, authenticate your login or remember that you are logged-in as you move
            through the Service.
            </div></li>
            <li>
                <div class="pB10"><em>Functionality cookies</em> allow us to remember choices you make (such as the region you're in) and tailor the
                Service to provide enhanced features and content for you. For instance, they can be used to remember your login details
                or remember your nickname when you next login. They may also be used to provide services you've requested, for example
                leaving a comment on the Service.
                </div>
            </li>

            <li>
                <div><em>Analytics & performances cookies</em> collect information about how the Service is used, for example which
                    pages users visit most often and if any error messages are displayed on certain pages. Information collected using
                    these cookies is used to monitor activity on the Service and understand how it is being used.
                </div>
            </li>
        </ul>
       </div>
       <div class="text">
        If you have disabled one or more cookies, we may still use information collected from cookies before your disabled preference
        was set. However, we will stop using the disabled cookie to collect any further information.
       </div>
       <div class="text">
        To find out more about cookies, including how to see what cookies have been set and how to manage and delete them,
        visit <a title="www.aboutcookies.org"
        href="https://www.aboutcookies.org/"
        target="_blank" rel="noopener noreferrer">www.aboutcookies.org.</a>
       </div>
       <div class="text">
        <span class="highlight">Pixel tags and other similar technologies.</span> Pixel tags (also known as web beacons and clear GIFs)
        may be used in connection with some Websites to, among other things, track the actions of users of the Websites (including email
        recipients), measure the success of our marketing campaigns and compile statistics about usage of the Websites and response rates.
        We and our service providers may also use pixel tags in HTML emails to our customers, to help us track email response rates,
        identify when our emails are viewed, track whether our emails are forwarded, and conduct analytics.
       </div>
       <div class="text">
        <span class="highlight">Log files.</span> Most browsers collect certain information, such as your IP address, device type,
        screen resolution, operating system version and internet browser type and version. This information is gathered automatically and
        stored in log files.
       </div>
       <div class="text">
        <span class="highlight">Third party analytics tools.</span> Our Websites use automated devices and applications operated by
        third parties, such as Google Analytics, which uses cookies and similar technologies to collect and analyze information about use
        of the Websites and report on activities and trends. This service may also collect information regarding the use of other websites,
        apps and online resources. You can learn about Google's practices by going to <a title="www.google.com/policies/privacy/partners/"
    href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">www.google.com/policies/privacy/partners/</a>, and you
        can opt out of them by downloading the Google Analytics opt-out browser add-on,
        available at <a title="https://tools.google.com/dlpage/gaoptout"
         href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout.</a>
       </div>
       <div class="text">
        <span class="highlight">Do-Not-Track signals.</span> Please note that our Websites do not recognize or respond to any signal
        which your browser might transmit through the so-called 'Do Not Track' feature your browser might have. If you wish to disable
        cookies on our Websites, you should not rely on any 'Do Not Track' feature your browser might have. For more information about
        do-not-track signals, please review <a title="https://allaboutdnt.com/"
        href="https://allaboutdnt.com/" target="_blank" rel="noopener noreferrer">https://allaboutdnt.com/</a>
       </div>

       <div class="sub-heading-privacy margin-top">
        7. INTEREST BASED ADVERTISING
       </div>
       <div class="text">
        On some of our Websites, we may work with third-party ad networks, analytics companies, measurement services and others
        ("third-party ad companies") to display advertising of our Services and to manage our advertising on third-party sites, mobile
        apps and online services. We and these third-party ad companies may use cookies, pixels tags and other tools to collect information
        on our Websites (and on third-party sites and services), such as browsing history, IP address, device ID, cookie and advertising
        IDs, and other identifiers, general location information and, with your consent, your device's geolocation information; we and
        these third-party ad companies use this information to provide you more relevant ads and content and to evaluate the success of
        such ads and content.
       </div>
       <div class="text">
        You can manage how your preferences regarding third party ad company cookies set by reading the cookie discussion above.
       </div>
       <div class="text">
        Please also see the cookie discussion above for information about the third parties we may work with to display targeted ads
        on third-party sites and to measure the success of our marketing and campaigns. You may also obtain more information about targeted
        or "interest-based advertising" and opt-out of many ad networks at the industry websites below:
        <ul>
            <li>Canada: <a title="www.youradchoices.ca" href="https://www.youradchoices.ca" target="_blank" rel="noopener noreferrer">
                www.youradchoices.ca
            </a></li>
            <li>EU: <a title="www.youronlinechoices.eu" href="https://www.youronlinechoices.eu" target="_blank" rel="noopener noreferrer">
                www.youronlinechoices.eu
            </a></li>
            <li>U.S.: <a title="www.aboutads.info" href="https://www.aboutads.info" target="_blank" rel="noopener noreferrer">
                www.aboutads.info
            </a></li>
        </ul>
       </div>

       <div class="sub-heading-privacy margin-top">
        8. SOCIAL FEATURES AND THIRD PARTY PLUG INS
       </div>
       <div class="text">
        Some of our Services may integrate with social sharing features and other related tools which let you log in to the Services
        or share actions you take on the Services. Your use of such features enables the sharing of information with your friends or
        the public, depending on the settings you establish with the social sharing service. Please refer to the privacy notice of
        those social sharing services for more information about how they handle the data you provide to or share through them. We do not
        receive or store passwords to your third-party social media accounts in this context.
       </div>
       <div class="sub-heading-privacy margin-top">
        9.	PRIVACY SHIELD WITHDRAWAL AND INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION
       </div>
       <div class="text">
        Cartus has withdrawn our certifications under the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework
        (collectively, “Privacy Shield”), meaning that we no longer participate in Privacy Shield or comply with the Privacy Shield.
        In accordance with Privacy Shield requirements, with respect to personal data that we previously collected pursuant to Privacy
        Shield and are retaining, we will continue to apply the Privacy Shield Principles, and annually confirm to the U.S. Department of
        Commerce our commitment to apply those principles to such data. For more information about Privacy Shield,
        please visit <a title="https://www.privacyshield.gov/" href="https://www.privacyshield.gov/"
        target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/</a>.‎
       </div>
       <div class="text">
        Our Websites are hosted on servers located in the United States or European Union. Note that some countries may not provide the
        same level of data protection as your home country. If you are located, for example, in European Economic Area or Switzerland
        (collectively, "EEA"), your Personal Information will be transferred to the United States and possibly to other jurisdictions
        (e.g., to your relocation departure and destination location), which may not provide the same level of protection under your
        local data protection laws. In such cases, we rely on suitable Personal Information transfer safeguards, such as the European
        Union standard contractual clauses. You may have the right to request a copy or information about those safeguards by contacting
        us as detailed below.
       </div>
       <div class="sub-heading-privacy margin-top">
        10. SECURITY
       </div>
       <div class="text">
        We use technical, administrative, and physical controls in place to help protect Personal Information from unauthorized access,
        use, and disclosure. Even so, despite our reasonable efforts, no security measure is ever perfect or impenetrable. In the event
        that the security of your account has been compromised, please immediately notify us in accordance with Section 15 below.
       </div>
       <div class="sub-heading-privacy margin-top">
        11. DATA RETENTION
       </div>
       <div class="text">
        We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Notice unless a
        longer retention period is required or permitted by law. We may retain Personal Information for longer where required by our legal
        and regulatory obligations, professional indemnity obligations, or where we believe it is necessary to establish, defend or protect
        our legal rights and interests or those of others. With respect to the data and files we handle as a processor, we retain this
        ‎information in accordance with applicable law and our clients’ instructions.
        </div>
        <div class="sub-heading-privacy margin-top">
            12.	CHILDREN
        </div>
        <div class="text">
        Our Websites are not directed to children under the age of 16 (“Children”), and we do not knowingly ‎collect any information,
        including Personal Information, from Children.‎
        </div>
        <div class="sub-heading-privacy margin-top">
            13. THIRD-PARTY WEBSITES
        </div>
        <div class="text">
            Our Websites may contain links to third-party services. We do not own, operate, or control the websites of third-parties.
            Accordingly, this Notice does not apply to any services maintained or operated by third-parties. When you click on those links,
            you will go to a third-party website where you will be subject to that service's privacy policy or similar statement and terms of
            use, and we encourage you to read that policy statement. We are not responsible for the privacy practices of other services, and
            we expressly disclaim any liability for their actions, including actions related to the use and disclosure of Personal Information
            by those third parties.
        </div>
        <div class="sub-heading-privacy margin-top">
            14. CHANGES TO THIS POLICY
        </div>
        <div class="text">
            We may amend this Notice at any time. If we make any material change in how we collect, use, disclose, or otherwise process
            Personal Information, we will prominently post a notice regarding such change on our Websites or by other means we deem appropriate.
            Any material changes to this Notice will be effective 10 days after our posting of the updated Notice or as otherwise required by
            applicable law. Where required to do so by law, we may seek your prior consent to any material changes we make to this Notice.
        </div>
        <div class="sub-heading-privacy margin-top">
            15. CONTACTING US/CORRECTION/UPDATING PERSONAL INFORMATION/EXERCISING YOUR RIGHTS
        </div>
        <div class="text">
            You may contact us through the methods stated below for a number of purposes, including: (1) if ‎you no longer wish to use the site;
            (2) to opt-out of communications or unsubscribe from any ‎industry updates; (3) to receive ‎information or special offers; (4) to
            provide feedback and comments on this Privacy Notice; (5) to ‎request access to your Personal Information, deletion of your incorrect
            Personal Information or ‎correction of your Personal Information; and (6) to make inquiries whether we hold or process on ‎behalf of a
            third party, any of your Personal Information or complaints/objections regarding our ‎Privacy Notice and/or privacy practices.‎
        </div>
        <table class="contact-info-tbl">
            <tr>
                <td class="contact-type">
                    By e-mail to:
                </td>
                <td>
                    <a title="Mail To: Cartus Information Protection"
                        href="mailto:InformationProtection@cartus.com">InformationProtection@cartus.com</a>
                </td>
            </tr>
            <tr>
                <td>
                    By mail:
                </td>
                <td>
                    <span class="highlight">Privacy Officer</span>, Cartus, 100 Reserve Road, Danbury, CT 06810‎<br>
                    Or if your relocation is being handled by our UK affiliate:
                    <br>
                    <span class="highlight">Director of Legal Services</span>, Cartus Limited, Unit 3, Arclite House, Century Road,
                    Peatmoor,
                    ‎Swindon, Wiltshire SN5 5YN
                </td>
            </tr>
            <tr>
                <td>
                    By phone:
                </td>
                <td>
                    (203) 205-1540
                </td>
            </tr>
            <tr>
                <td>
                    Toll free US phone:
                </td>
                <td>
                    (877) 651-7356
                </td>
            </tr>
            <tr>
                <td>
                    By phone (Singapore):
                </td>
                <td>
                    +65 6880 5804
                </td>
            </tr>
        </table>


        <div class="text padding-top">
            We will respond to your request within a reasonable timeframe, but in no event later than 45 ‎days, or such
            lesser period prescribed by applicable law.‎
        </div>

        <div class="sub-heading-privacy margin-top">
            ADDITIONAL INFORMATION FOR RESIDENTS OF CERTAIN JURISDICTIONS
        </div>
        <div class="side-heading">
            15(a). European Union/European Economic Area/United Kingdom:
        </div>
        <div class="text">
            If you are a resident of the EU, European Union or United Kingdom you may have the following ‎rights that
            you should exercise through the contacts listed above or directly through your ‎employer or membership
            group. Cartus will respond to you or aid your employer when you ‎exercise the following rights:‎
        </div>
        <div class="text">
            <ul>
                <li>
                    The right to require a copy of your personal data;‎
                </li>
                <li>
                    The right to require that inaccurate or out of date personal data be corrected;‎
                </li>
                <li>
                    The right to request that personal data is erased when it is no longer necessary to be retained;‎
                </li>
                <li>
                    If consent it the basis for processing, the right to withdraw your consent;‎
                </li>
                <li>
                    The right to data portability, if applicable;‎
                </li>
                <li>
                    The right, where there is a dispute in relation to the accuracy or processing of your personal
                    ‎data, to request a restriction be placed on further processing;‎
                </li>
                <li>
                    The right to object to processing, if applicable; and
                </li>
                <li>
                    You also have the right to lodge a complaint with your applicable data protection authority.‎
                </li>

            </ul>
        </div>

        <div class="text">
          Cartus has appointed Data Protection Representative trading as “DataRep” as its Data Protection Representative as required under law.
        </div>
        <div class="text">
          <ul>
            <li>
              In the event a resident of the EU or European Economic Area wishes to contact DataRep, you may do so using one of the following options:
            </li>
            <li>
              By E-mail: <a href="mailto:datarequest@datarep.com">datarequest@datarep.com</a>  (Please quote “Cartus” in your correspondence)
            </li>
            <li>
              By Online Webform: <a href="https://www.datarep.com/data-request" target="_blank" rel="noopener noreferrer">www.datarep.com/data-request</a>
            </li>
            <li>
              By Post: Please post your requests addressed to “DataRep” at the applicable address for your location. Please <a href="https://www.cartus.com/files/9316/6558/4967/DataRep-_Office_Location_Details.pdf" target='_blank' rel="noopener noreferrer" >click here</a> for the list of postal addresses.
            </li>
          </ul>
        </div>

        <div class="text">
            <span class="highlight"> 15(b). California Residents </span>(Last Updated: 5th October 2022‎)
            <br />
            In this section we provide information for California residents, as required under California ‎privacy laws,
            including the California Consumer Privacy Act (<span class="highlight">"CCPA"</span>), which requires that
            we ‎provide California residents
            certain specific information about how we handle their personal ‎information, whether collected online or
            offline. This section does not address or apply to our ‎handling of publicly available information made
            lawfully available by state or federal ‎governments or other personal information that is subject to an
            exemption under CCPA.‎
        </div>
        <div class="text">
            <span class="highlight">Categories of Personal Information that we collect and disclose.</span> Our
            collection, use and ‎disclosure of
            personal information about a California resident will vary depending upon the ‎circumstances and nature of
            our interactions or relationship with such resident. The table below ‎sets out generally the
            categories of Personal Information (as defined by the CCPA) about ‎California residents that we
            collect and disclose to others for a business purpose. ‎
        </div>
        <!---table 2 start-->
        <table class="personal-info-tbl">
            <tr>
                <th>Categories of Personal Information</th>
                <th>May We Disclose this Information?</th>
                <th>Third Parties to Whom We May Disclose this Information</th>
            </tr>
            <tr>
                <td>
                    <span>Identifiers</span>: includes direct identifiers such as a real ‎name, alias, address, user ID,
                    username,
                    account number; phone number, unique ‎personal identifier, online identifier, IP address, ‎app ID,
                    email address, account name, social security ‎number, social insurance number, driver's license
                    ‎number, passport number, or other similar identifiers.‎
                </td>
                <td class="disclose-info">Yes</td>
                <td>
                    <ul>
                        <li>service providers </li>
                        <li>advisors and agents</li>
                        <li>government entities and law enforcement</li>
                        <li>affiliates and subsidiaries </li>
                        <li>real estate professionals</li>
                        <li>your general location to data analytics providers</li>
                        <li>internet service providers</li>
                        <li>business customer/client</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Customer records</span>: includes Personal Information that you or your employer or
                    membership group provide us in order to obtain our products and services, such as ‎name,
                    account name and number, signature, contact information, employment information, social security
                    number, social insurance number, passport number, driver's license and other government
                    identifiers, insurance information and financial ‎information.  For example, this may include
                    information collected when an individual registers for our services.
                </td>
                <td class="disclose-info">Yes</td>
                <td>
                    <ul>
                        <li>service providers </li>
                        <li>advisors and agents</li>
                        <li>government entities and law enforcement</li>
                        <li>affiliates and subsidiaries </li>
                        <li>internet service providers may collect your IP address as a function of using our services
                        </li>
                        <li>operating systems and platforms</li>
                        <li>business customer/client</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Commercial information</span>: includes records of personal property, products or ‎services
                    purchased,
                    ‎obtained, or considered, or other purchasing or use ‎histories or tendencies.‎
                </td>
                <td class="disclose-info">Yes</td>
                <td>
                    <ul>
                        <li>service providers </li>
                        <li>advisors and agents</li>
                        <li>government entities and law enforcement</li>
                        <li>affiliates and subsidiaries </li>
                        <li>advertising networks</li>
                        <li>your general location to data analytics providers</li>
                        <li>business customer/client</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Usage Data</span>: includes browsing history, ‎clickstream data, search history, and
                    information ‎regarding
                    a resident's interaction with an internet ‎website, application, or ‎advertisement, including access
                    logs and other usage data related to your use ‎of ‎any company websites, applications or other
                    online services.‎
                </td>
                <td class="disclose-info">Yes</td>
                <td>
                    <ul>
                        <li>service providers </li>
                        <li>advisors and agents</li>
                        <li>government entities and law enforcement</li>
                        <li>affiliates and subsidiaries </li>
                        <li>internet service providers may collect your IP address as a function of using our services
                        </li>
                        <li>business customer/client</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Geolocation data</span>: includes precise location information about a particular individual
                    or ‎device.‎
                </td>
                <td class="disclose-info">Yes</td>
                <td>
                    <ul>
                        <li>service providers </li>
                        <li>advisors and agents</li>
                        <li>government entities and law enforcement</li>
                        <li>affiliates and subsidiaries </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Audio, video and other electronic data</span>: includes audio, electronic, visual, thermal,
                    ‎olfactory, or
                    similar information ‎such as thermal screenings and CCTV footage (e.g., collected from visitors to
                    our offices), photographs you provide to us, and call ‎recordings (e.g., of customer support calls).
                </td>
                <td class="disclose-info">Yes</td>
                <td>
                    <ul>
                        <li>service providers </li>
                        <li>advisors and agents</li>
                        <li>government entities and law enforcement</li>
                        <li>affiliates and subsidiaries </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Professional Information</span>: includes professional or employment-related information.‎
                </td>
                <td class="disclose-info">Yes</td>
                <td>
                    <ul>
                        <li>service providers </li>
                        <li>advisors and agents</li>
                        <li>government entities and law enforcement</li>
                        <li>affiliates and subsidiaries </li>
                        <li>business customer/client</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Education information</span>: includes information about an individual’s educational history
                    (such as the
                    schools attended, degrees you were awarded, and associated dates).
                </td>
                <td class="disclose-info">Yes</td>
                <td>
                    <ul>
                        <li>service providers </li>
                        <li>advisors and agents</li>
                        <li>government entities and law enforcement</li>
                        <li>affiliates and subsidiaries </li>
                        <li>business customer/client</li>
                    </ul>
                </td>
            </tr>
        </table>
        <!---table 2 end---->
        <div class="text">
            In addition, we may disclose any of the categories of Personal Information we collect about you to other third parties as
            directed by or consented to by you, or where necessary to fulfil your or your employer’s or membership group’s specific requests.
        </div>
        <div class="text">
            <span class="highlight">Do We “Sell” Personal Information?</span> The CCPA defines a "sale" as disclosing or making
            available to a third-party Personal Information in exchange for monetary or other valuable consideration. While we do not
            disclose Personal Information to third parties in exchange for monetary compensation, as defined by the CCPA, we may “sell”
            Identifiers, Usage Data and Inferences to ad networks and data analytics providers, via cookies and tags on certain of our
            websites (e.g., in order to improve and measure our advertising and marketing campaigns).
        </div>
        <div class="text">
            <span class="highlight">Sources of Personal Information. </span>As further described above, we may collect Personal Information
            from the following sources:
        </div>
        <div class="text">
            <ul>
                <li>
                    directly from the individual
                </li>
                <li>
                    advertising networks
                </li>
                <li>
                    social networks
                </li>
                <li>
                    government entities
                </li>
                <li>public records</li>
                <li>data analytics providers</li>
                <li>internet service providers</li>
                <li>operating systems and platforms</li>
                <li>our business clients</li>
            </ul>
        </div>
        <div class="text">
            <span class="highlight">Rights of California Residents. </span>California residents have the following rights under CCPA:‎
        </div>
        <div class="text">
            <ul class="rights-of-california-residents-list">
                <li>
                    <span>Do-not-sell (opt-out)</span>: to opt-out of our sale of their Personal Information.
                    The link to opt out can be found in the footer of this website.
                </li>
                <li>
                    <span>Deletion</span>: to request deletion of their personal ‎information that we have collected about them and to have such
                        Personal Information ‎deleted, subject to certain exemptions.
                </li>
                <li>
                    <span>Request to Know</span>: with respect to Personal Information that we have ‎collected about them in the prior 12 months,
                    to require that we disclose the following to them (up to twice per year and subject to certain exceptions):‎
                    <div class="pT10">
                    <ul>
                        <li>categories of Personal Information collected;‎</li>
                        <li>categories of sources of Personal Information;‎</li>
                        <li>categories of Personal Information that we have disclosed or shared with a third party for a ‎
                            business purpose or sold;‎</li>
                        <li>categories of third parties to whom we have disclosed for a business purpose or sold their
                            Personal Information;</li>
                        <li>the business or commercial purposes for collecting or selling their Personal Information; and</li>
                        <li>a copy of the specific pieces of Personal Information we have collected about them‎.</li>
                    </ul>
                </div>
                </li>
                <li>
                    <span>Right to non-discrimination</span>: the right not to be subject to discriminatory treatment for exercising their
                    rights under the CCPA.
                </li>
            </ul>
        </div>
        <div class="text">
            We will respond to verifiable requests received ‎from California residents as required by law.‎‎
        </div>
        <div class="text">
            <span class="highlight">Financial Incentives. </span>We may make available certain programs or offerings that are considered
            “financial incentives” under the CCPA as described below. We will provide you with notice and obtain your consent prior to your
            participation in any such program.
        </div>
        <div class="text">
            <span class="highlight">Submitting Requests. </span>Requests to opt-out of sales, requests to know, and requests to delete
            may be submitted:‎
        </div>
        <div class="text">
            <ul>
                <li>
                    By email to <a title="Mail To: Cartus Information Protection"
                        href="mailto:InformationProtection@cartus.com">InformationProtection@cartus.com</a>
                </li>
                <li>
                    All requests: By contacting us at (877) 651-7356 (toll free)‎
                </li>
            </ul>
        </div>
        <div class="text">When you submit a request to know or a request to delete, we will take steps to verify your request by
            matching the information provided by you with the information we have in our records. You must provide us with any requested
            information or otherwise provide us with this information via the above toll-free number to verify your request.
            In some cases, we may request additional information in order to verify your request or where necessary to process your request.
            If we are unable to adequately verify a request, we will notify the requestor. Authorized agents may initiate a request on behalf
            of another individual by contacting us at <a title="Mail To: Cartus Information Protection"
            href="mailto:InformationProtection@cartus.com">InformationProtection@cartus.com</a>; authorized agents will be required to provide proof of
            their authorization and we may also require that the relevant consumer directly verify their identity and the authority of the
            authorized agent. </div>
        <div class="text">
            <span class="highlight">Rights Under California Shine the Light Law.</span> California residents may request from businesses ‎with
            whom they have an established business relationship (1) a list of categories of personal ‎information, such as name, address,
            e-mail address, and the type of services provided to the ‎customer, that a business has disclosed to third parties during the
            immediately preceding ‎calendar year for the third parties' direct marketing purposes and (2) the names and addresses ‎of all such
            third parties. Cartus does not provide Personal Data to third parties for direct ‎marketing purposes.
        </div>
        <!--------------------------------------------->
    </div>
    <div class="footer">
      {{copyright}}
    </div>
</div>
