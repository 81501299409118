import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SpringboardTimeoutService {
    constructor() {
        /** Keeps all the data related to Timeout */
        this.timeoutData = new BehaviorSubject(null);
        this.timeoutData$ = this.timeoutData.asObservable();
    }
}
SpringboardTimeoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpringboardTimeoutService_Factory() { return new SpringboardTimeoutService(); }, token: SpringboardTimeoutService, providedIn: "root" });
