import { of } from 'rxjs';
import * as i0 from "@angular/core";
/** Service to get and set user preferences */
export class UserPreferencesService {
    /** standard constructor for services */
    constructor() { }
    /**
     * Get user preference
     */
    getPreference(key, persistent) {
        if (persistent) {
            return of(JSON.parse(localStorage.getItem(key)));
        }
        else {
            return of(JSON.parse(sessionStorage.getItem(key)));
        }
    }
    /**
     * Set user preference
     */
    setPreference(userPreference) {
        if (userPreference.Persist) {
            localStorage.setItem(userPreference.Key, JSON.stringify(userPreference.Value));
        }
        else {
            sessionStorage.setItem(userPreference.Key, JSON.stringify(userPreference.Value));
        }
    }
}
UserPreferencesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserPreferencesService_Factory() { return new UserPreferencesService(); }, token: UserPreferencesService, providedIn: "root" });
