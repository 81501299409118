import { Component, OnInit, ViewEncapsulation, HostBinding} from '@angular/core';
import { copyright } from 'src/app/core/constants';
import { Router } from '@angular/router';
 
@Component({
 selector: 'app-footer',
 templateUrl: './footer.component.html',
 styleUrls: ['./footer.component.scss'],
 encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
@HostBinding('class') class = 'app-footer' 
 /**property to print copyright string */
 flexProperty = 'column';
 showFullWidth = false;
 cookiesAccepted = false;
 readonly copyright = copyright;
 constructor(
 private router: Router
 ) {}
 
 ngOnInit() {
 //check cookies policy accepted
 const cookies = document.cookie.split(';');
 let cookiesItems = cookies.map(val=>{ return { key:val.split('=')[0].trim(), value:val.split('=')[1] }})
 let hasKey = cookiesItems.find(item=>{ return item.key === "notice_preferences" })
 if (typeof hasKey !== "undefined") {
    this.cookiesAccepted = true
 } else {
    this.cookiesAccepted = false
 }
}}