<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div fxFlex="100%" fxFlexFill fxLayout fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center"
    class="FooterAlign">
    <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
    <div class="selfRegistration" fxFlex="50%" fxFlexFill fxLayout="column" fxLayout.xs="column" fxLayout.sm="column"
        fxLayoutAlign="center">
        <div [hidden]="isLoading">
            <div class="main-content">
              <app-logo [logoConfig]="logoConfig"></app-logo>
              <div class="welcome-txt">{{templateString.WELCOME}}</div>
              <div class="info-txt body-copy">{{templateString.INFORMATION}}</div>
              <mat-horizontal-stepper class="panel-bg" #stepper (selectionChange)="onStepChange($event)">
                  <mat-step>
                      <div *ngIf="stepper.selectedIndex==0 && userDetails">
                          <div>
                              <app-self-registration-validate-user [step]="0" [userDetails]="userDetails"
                                  (notify)="onNotify($event)">
                              </app-self-registration-validate-user>
                          </div>
                      </div>
                  </mat-step>
                  <mat-step>
                      <div *ngIf="stepper.selectedIndex==1 && userDetails">
                          <div>
                              <app-self-registration-create-user [step]="1" [userDetails]="userDetails"
                                  (notify)="onNotify($event)">
                              </app-self-registration-create-user>
                          </div>
                      </div>
                  </mat-step>
                  <mat-step>
                      <div *ngIf="stepper.selectedIndex==2">
                          <div>
                              <app-self-registration-success [step]="2" [userDetails]="userDetails">
                              </app-self-registration-success>
                          </div>
                      </div>
                  </mat-step>
              </mat-horizontal-stepper>
          </div>
          <app-footer></app-footer>
        </div>
    </div>
</div>