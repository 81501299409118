/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./springboard-timeout.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./springboard-timeout.component";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/material/snack-bar";
var styles_SpringboardTimeoutComponent = [i0.styles];
var RenderType_SpringboardTimeoutComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SpringboardTimeoutComponent, data: {} });
export { RenderType_SpringboardTimeoutComponent as RenderType_SpringboardTimeoutComponent };
export function View_SpringboardTimeoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.FormatTimePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "iddle-time-out-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Still There?"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you stay inactive you'll be logged out in "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "counter"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["done"]))], function (_ck, _v) { _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.counter)); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 10).inline; var currVal_2 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_SpringboardTimeoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "springboard-timeout", [], null, null, null, View_SpringboardTimeoutComponent_0, RenderType_SpringboardTimeoutComponent)), i1.ɵdid(1, 245760, null, 0, i2.SpringboardTimeoutComponent, [i1.ChangeDetectorRef, i5.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpringboardTimeoutComponentNgFactory = i1.ɵccf("springboard-timeout", i2.SpringboardTimeoutComponent, View_SpringboardTimeoutComponent_Host_0, {}, {}, []);
export { SpringboardTimeoutComponentNgFactory as SpringboardTimeoutComponentNgFactory };
