/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./avatar.component";
var styles_AvatarComponent = [i0.styles];
var RenderType_AvatarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AvatarComponent, data: {} });
export { RenderType_AvatarComponent as RenderType_AvatarComponent };
export function View_AvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "user-water"], ["role", "button"], ["tabindex", "0"]], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.firstName == null) ? null : _co.firstName.charAt(0)) + ",") + ((_co.lastName == null) ? null : _co.lastName.charAt(0))); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.firstName == null) ? null : _co.firstName.charAt(0)); var currVal_2 = ((_co.lastName == null) ? null : _co.lastName.charAt(0)); _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_AvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-avatar", [["class", "app-avatar"]], null, null, null, View_AvatarComponent_0, RenderType_AvatarComponent)), i1.ɵdid(1, 114688, null, 0, i2.AvatarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvatarComponentNgFactory = i1.ɵccf("app-avatar", i2.AvatarComponent, View_AvatarComponent_Host_0, { firstName: "firstName", lastName: "lastName" }, {}, []);
export { AvatarComponentNgFactory as AvatarComponentNgFactory };
