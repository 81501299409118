<div class="footerWrapper" [ngClass]="{ 'footerWrapper-full-width': showFullWidth}">
  <div class="copyRight" [ngClass]="{ 'copyRight-full-width': showFullWidth}">{{copyright}}</div>
  <div class="footerlink" [ngClass]="{ 'footerlink-full-width': showFullWidth}">
    <div class="personalInfo">
      <ul>
        <li><a target="_blank" href="https://submit-irm.trustarc.com/services/validation/0080a1bc-5f59-48fc-993e-83efc1029056">Do Not Sell My Personal Information</a></li>
      </ul>
    </div>
  <ul>
  <li><a target="_blank" [routerLink]="['/termsOfUse']">Terms of Use</a></li>
  <li><a target="_blank" [routerLink]="['/privacyNotice']">Privacy Notice</a></li>
  <li><a target="_blank" [routerLink]="['/contactUs']">Contact Us</a></li>
  </ul>
  <div id="teconsent" [ngStyle]="{'display':(this.cookiesAccepted === true)?'block':'none'}" ></div>
  </div>
</div>
