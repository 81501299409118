import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PersistenceService, StorageType } from 'angular-persistence';
import { User } from '../../../../core/models/User';
import { ManageUserSharedService } from '../../../../core/services/manage-user-shared.service';

/** Class for  SelfRegistrationSuccess */
@Component({
  selector: 'app-self-registration-success',
  templateUrl: './self-registration-success.component.html',
  styleUrls: ['./self-registration-success.component.scss']
})
export class SelfRegistrationSuccessComponent implements OnInit {
  /** Input parameter step number received from parent component */
  @Input() step: number;
  /** Input parameter userDetails received from parent component */
  @Input() userDetails: User;
  /** string constants to be displayed in self registration success page */
  templateString = {
    LOGIN_BTN: 'Log In',
    CONGRATS_TXT: 'Congratulations!',
    ACCOUNT_CREATED_TXT: 'Your account has been created successfully'
  };

  /**
   * Base constructor
   * @param rourer for route navigations
   * @param persistenceService PersistenceService
   */
  constructor(private readonly router: Router, private readonly persistenceService: PersistenceService,
    private readonly manageMoveSharedService: ManageUserSharedService
  ) { }

  ngOnInit() {
    this.manageMoveSharedService.updateData(this.userDetails);
  }

  /** Store the current step in session */
  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit(): void {
    this.persistenceService.set('currentStep', JSON.stringify(this.step),
      { type: StorageType.SESSION });
  }

  /** Redirects to Login Page */
  redirectLogin() {
    this.router.navigate(['login']);
  }
}
