<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div fxLayout="column">
  <app-header [redirect]="true" role="navigation"></app-header>
  <div fxFlex="100%" class="change-password-bg-color"  fxLayout fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="center">
    <ng-container>
        <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
    </ng-container>
    <div class="main-content" role="main">
      <h1 fxLayoutAlign="center">Change Password</h1>
      <div class="info-txt">
          {{templateString.INFORMATION}}
      </div>
      <div class="form-container" fxLayoutAlign="center">
        <form fxLayout="row"  [formGroup]="updatePasswordSetupForm" fxFlex>
          <div class="password-form panel-bg change-password-layout-bg-color">
            <div fxLayout="column">
              <div fxLayout="row" fxLayoutGap="40px" fxLayoutGap.xs="0">
                  <div fxFlex="60" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div class="mat-form-input">
                      <mat-form-field appearance="fill">
                        <mat-label>{{templateString.currentPassword}}</mat-label>
                        <input matInput placeholder="Current Password" type="password"
                          formControlName="currentPassword" required>
                        <mat-error>{{ getErrorMessage('currentPassword') }}</mat-error>
                        <mat-error
                          *ngIf="updatePasswordSetupForm.controls['currentPassword'].hasError('notCorrect')">
                          {{ incorrectPasswordError }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                      <ng-container *ngIf="responsiveView">
                        <ng-container *ngTemplateOutlet="passwordInstructionTemplate"></ng-container>
                      </ng-container>
                      <div class="mat-form-input">
                        <mat-form-field appearance="fill">
                          <mat-label>{{templateString.newPassword}}</mat-label>
                          <input matInput placeholder="New Password" type="password"
                              formControlName="newPassword" (focus)="clearError('newPassword')" (focusout)="checkPassword()" required>
                          <mat-error>{{ getErrorMessage('newPassword') }}</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="mat-form-input">
                        <mat-form-field appearance="fill">
                          <mat-label>{{templateString.confirmNewPassword}}</mat-label>
                          <input matInput placeholder="Confirm New Password" type="password"
                            formControlName="confirmNewPassword" (focus)="clearError('confirmNewPassword')" (focusout)="checkPassword()" required>
                          <mat-error>{{ getErrorMessage('confirmNewPassword') }}</mat-error>
                          <mat-error
                              *ngIf="updatePasswordSetupForm.controls['confirmNewPassword'].hasError('notSame')">
                              {{ templateString.PASSWORD_NOT_MATCH }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                  </div>
                  <div fxLayoutAlign="end end" fxFlex="40" fxHide.xs fxHide.sm>
                    <ng-container *ngIf="!responsiveView">
                      <ng-container *ngTemplateOutlet="passwordInstructionTemplate"></ng-container>
                    </ng-container>
                  </div>
              </div>
              <div class="change-errorBlock" *ngIf="updateError!=''">
                <span>{{updateError}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="0" class="updateErrorMessage">
                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="100%" class="login-text-btn">
                  <button type="button" (click)="cancel()">CANCEL</button>
                </div>
                <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="100%" class="login-contain-change-btn">
                  <button type="submit" (click)="changeUserPassword()"
                    [disabled]="!updatePasswordSetupForm.valid">
                      {{templateString.CHANGE_PASS_BTN}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ng-template #passwordInstructionTemplate>
            <div class="password-instruction">
              <div class="heading">
                  {{templateString.PASSWORD_INSTRUCTION_TITLE}}
              </div>
              <ul class="instruction-list">
                <li *ngFor="let instruction of passwordInstructions">
                    {{instruction}}
                </li>
              </ul>
              <div class="additional-instruction">
                  {{templateString.PASSWORD_ADDITIONAL_INSTRUCTION}}
              </div>
            </div>
          </ng-template>
        </form>
      </div>
      <ng-container>
        <app-footer></app-footer>
      </ng-container>
    </div>
  </div>
</div>
