/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-timeout.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./session-timeout.component";
import * as i7 from "@angular/material/snack-bar";
var styles_SessionTimeoutComponent = [i0.styles];
var RenderType_SessionTimeoutComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SessionTimeoutComponent, data: {} });
export { RenderType_SessionTimeoutComponent as RenderType_SessionTimeoutComponent };
export function View_SessionTimeoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "invitation"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "sent"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "snack-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "loader-close-icon mat-icon notranslate"], ["id", "dismiss"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "column"; _ck(_v, 3, 0, currVal_1); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data; _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 7).inline; var currVal_4 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_3, currVal_4); }); }
export function View_SessionTimeoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-session-timeout", [], null, null, null, View_SessionTimeoutComponent_0, RenderType_SessionTimeoutComponent)), i1.ɵdid(1, 114688, null, 0, i6.SessionTimeoutComponent, [i7.MatSnackBarRef, i7.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionTimeoutComponentNgFactory = i1.ɵccf("app-session-timeout", i6.SessionTimeoutComponent, View_SessionTimeoutComponent_Host_0, {}, {}, []);
export { SessionTimeoutComponentNgFactory as SessionTimeoutComponentNgFactory };
