import {NgModule, InjectionToken} from '@angular/core';
import {Routes, RouterModule, ActivatedRouteSnapshot} from '@angular/router';
import {LoginComponent} from './public/components/login/login.component';
import {LogoutComponent} from './public/components/logout/logout.component';
import {ExternRouteComponent} from './public/components/extern-route/extern-route.component';
import {PrivacyNoticeComponent} from './public/components/privacy-notice/privacy-notice.component';
import {SelfRegistrationComponent} from './public/components/selfRegistration/self-registration.component';
import {UpdatePasswordComponent} from './public/components/update-password/update-password.component';
import {UpdatePasswordSucessPageComponent} from './public/components/update-password-sucess-page/update-password-sucess-page.component';
import {DuplicateRegistrationComponent} from './public/components/duplicateRegistration/duplicate-registration.component';
import {InvalidRegistrationComponent} from './public/components/invalidRegistration/invalid-registration.component';
import {ContactUsComponent} from './public/components/contact-us/contact-us.component';
import {PrivacyStatementComponent} from './public/components/privacy-statement/privacy-statement.component';
import {TermsOfUseComponent} from './public/components/terms-of-use/terms-of-use.component';
import {SpringboardComponent} from './public/components/springboard/springboard.component';
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/google',
    component: LoginComponent,
    data: {googleIdp: true}
  },
  {
    path: 'login/:code',
    component: LoginComponent
  },
  {
    path: 'registration/:partyId',
    component: SelfRegistrationComponent,
  },
  {
    path: 'registration/:partyId/step/:id',
    component: SelfRegistrationComponent,
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider
    },
    component: ExternRouteComponent
  },
  {
    path: 'updatePassword',
    component: UpdatePasswordComponent
  },
  {
    path: 'changePassword',
    component: UpdatePasswordComponent
  },
  {
    path: 'updatePassword/successpage',
    component: UpdatePasswordSucessPageComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'logout/:code',
    component: LogoutComponent
  },
  // {
  //   path: 'privacyNotice',
  //   component: PrivacyNoticeComponent
  // },
  {
    path: 'duplicateRegistration',
    component: DuplicateRegistrationComponent
  },
  {
    path: 'invalidRegistration',
    component: InvalidRegistrationComponent
  },
  {
    path: 'contactUs',
    component: ContactUsComponent
  },
  {
    path: 'privacyNotice',
    component: PrivacyStatementComponent
  },
  {
    path: 'termsOfUse',
    component: TermsOfUseComponent
  },
  {
    path: 'springboard',
    component: SpringboardComponent
  },
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    }
  ],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
