import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "./app-config.service";
import * as i2 from "ngx-cookie-service";
// This service handles the setting of logos and cookie settings and removals
export class ManageCookieLogoService {
    constructor(appConfig, cookieService) {
        this.appConfig = appConfig;
        this.cookieService = cookieService;
        /*Available Sites*/
        this.authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices'];
        /* Okta session timeout */
        this.idleTimeoutMinutes = 55;
        /** domain variable */
        this.domain = '.cartus.com';
    }
    /**
    * To set the session cookie in the browser
    * @param tokens - oktaResponse tokens
    * @param cookieName - cookie name
    */
    setSessionCookie(tokens) {
        if (this.appConfig.getConfig('local')) {
            this.cookieService.set('car-ses-tok', tokens.accessToken, undefined, undefined, undefined, undefined, 'Lax'); // Developer
        }
        else {
            this.cookieService.set('car-ses-tok', tokens.accessToken, null, // We are relying on Okta session expiration
            '/', this.domain, true);
        }
    }
    /**
    * To remove specific cookies from the browser
    * @param cookieNames - array of cookie names
    */
    removeCookies(cookieNames) {
        // work-around for deleting cookies since delete is broken in ngx-cookie-service
        // in the last version compatible with Angular 8
        const oldDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
        cookieNames.forEach(cookieName => {
            if (cookieName == 'car-ses-tok' || cookieName == "lastAction") {
                if (this.appConfig.getConfig('local')) {
                    this.cookieService.set(cookieName, '', oldDate, '/', 'localhost', false, 'Lax');
                }
                else {
                    this.cookieService.set(cookieName, '', oldDate, '/', this.domain, false, 'Lax');
                }
            }
        });
    }
    /**
    * To remove all cookies from the browser except those that are whitelisted
    */
    removeAllCookies() {
        // work-around for deleting cookies since delete is broken in ngx-cookie-service
        // in the last version compatible with Angular 8
        const oldDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
        const domain = this.appConfig.getConfig('local') ? 'localhost' : this.domain;
        const cookiesToRetain = [
            'cmapi_cookie_privacy',
            'cmapi_gtm_bl',
            'notice_behavior',
            'notice_gdpr_prefs',
            'notice_preferences'
        ];
        let cookiesToRemove = this.getAllCookies();
        if (Object.keys(cookiesToRemove || {}).length > 0) {
            for (const cookieName in cookiesToRemove) {
                if (cookiesToRemove.hasOwnProperty(cookieName) &&
                    cookiesToRetain.indexOf(cookieName) === -1) {
                    this.cookieService.set(cookieName, '', oldDate, '/', domain, false, 'Lax');
                }
            }
        }
    }
    /**
    * To get all cookies from the browser
    */
    getAllCookies() {
        return this.cookieService.getAll();
    }
    /**
     * To get a cookie
     * @param name - cookie name
     */
    getCookie(name) {
        return this.cookieService.get(name);
    }
    /**
     * To set a cookie
     * @param name - cookie name
     * @param value - cookie value
     * @param expires - number of days until the cookies expires
     * @param path - cookie path
     * @param domain - cookie domain
     * @param secure - secure flag
     * @param sameSite - OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `None`
     */
    setCookie(name, value, expires, path, domain, secure, sameSite) {
        if (this.appConfig.getConfig('local')) {
            this.cookieService.set(name, value, expires, path, 'localhost', true, sameSite);
        }
        else {
            this.cookieService.set(name, value, expires, path, domain, true, sameSite);
        }
    }
    /**
    * To set the logo for the template based on the referredU
    * @param referrerdURL - source app url
    * @return A response containing the Logo model (object)
    */
    setLogo(referredURL) {
        // To hold the logo type
        let logo;
        // Application URLs
        const movePro360URL = this.appConfig.getConfig(this.authorizedLocations[0]).toString().split('|');
        const mobilifyHRURL = this.appConfig.getConfig(this.authorizedLocations[1]).toString().split('|');
        const mobilifyUIURL = this.appConfig.getConfig(this.authorizedLocations[2]).toString().split('|');
        const supplierPortalURL = this.appConfig.getConfig(this.authorizedLocations[3]).toString().split('|');
        const benefitsBuilderURL = this.appConfig.getConfig(this.authorizedLocations[4]).toString().split('|');
        const compensationServicesURL = this.appConfig.getConfig(this.authorizedLocations[5]).toString().split('|');
        // /* For movePro360 Logo */
        if (referredURL === movePro360URL[0].replace(/(\/#|\/|#)$/, '') ||
            (movePro360URL.length > 1 && referredURL === movePro360URL[1].replace(/(\/#|\/|#)$/, ''))) {
            return 'movePro360';
        }
        // /* For benefitsBuilder Logo */
        if (!logo && (referredURL === benefitsBuilderURL[0].replace(/(\/#|\/|#)$/, '') ||
            (benefitsBuilderURL.length > 1 && referredURL === benefitsBuilderURL[1].replace(/(\/#|\/|#)$/, '')))) {
            return 'benefitsBuilder';
        }
        // /* For mobilifyHR Logo */
        if (!logo && (referredURL === mobilifyHRURL[0].replace(/(\/#|\/|#)$/, '') ||
            (mobilifyHRURL.length > 1 && referredURL === mobilifyHRURL[1].replace(/(\/#|\/|#)$/, '')))) {
            return 'mobilifyHR';
        }
        // /* For mobilifyUI Logo */
        if (!logo && (referredURL === mobilifyUIURL[0].replace(/(\/#|\/|#)$/, '') ||
            (mobilifyUIURL.length > 1 && referredURL === mobilifyUIURL[1].replace(/(\/#|\/|#)$/, '')))) {
            return 'mobilifyUI';
        }
        // /* For supplierPortal Logo */
        if (!logo && (referredURL === supplierPortalURL[0].replace(/(\/#|\/|#)$/, '')) ||
            (supplierPortalURL.length > 1 && referredURL === supplierPortalURL[1].replace(/(\/#|\/|#)$/, ''))) {
            return 'supplierPortal';
        }
        // /* For compensationServices Logo */
        if (!logo && (referredURL === compensationServicesURL[0].replace(/(\/#|\/|#)$/, '')) ||
            (compensationServicesURL.length > 1 && referredURL === compensationServicesURL[1].replace(/(\/#|\/|#)$/, ''))) {
            return 'compensationServices';
        }
        // /* For Cartus Logo - used for springboard when no referrer */
        if (!logo && referredURL === '') {
            return 'cartus';
        }
    }
    /**
    * To set the logo for the template based on userDetails
    * @param userDetails - User Model object
    * @return Logo string
    *    */
    setLogoFromUser(userDetails) {
        if (userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
            return 'mobilifyHR';
        }
        else if (userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
            return "movePro360";
        }
        else if (userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
            return 'benefitsBuilder';
        }
        else if (userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API
            return 'compensationServices';
        }
    }
}
ManageCookieLogoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManageCookieLogoService_Factory() { return new ManageCookieLogoService(i0.ɵɵinject(i1.AppConfigService), i0.ɵɵinject(i2.CookieService)); }, token: ManageCookieLogoService, providedIn: "root" });
