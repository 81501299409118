import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { BaseClientService } from "./base-client.service";
import { RemoteLoggingService } from "./remote-logging.service";
import * as i0 from "@angular/core";
import * as i1 from "./app-config.service";
import * as i2 from "./base-client.service";
import * as i3 from "./remote-logging.service";
export class SpringboardSetupService {
    constructor(appConfig, baseClient, logSvc) {
        this.appConfig = appConfig;
        this.baseClient = baseClient;
        this.logSvc = logSvc;
    }
    /**
     * returns an array of PartyAndOrganizationDetails
     */
    getContexts(token) {
        const headers = new HttpHeaders({ 'authorization': token });
        const params = new HttpParams();
        return this.baseClient.getWithOptions("/user/appcontext", "get the springboard contexts list", params, headers).pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.logSvc.logError(err);
            return of(empty);
        }));
    }
    /**
     * returns the applications available to the app-context
     */
    getApps(token, partyId) {
        const headers = new HttpHeaders({ 'authorization': token, 'app-context': partyId });
        return this.baseClient.getWithOptions("/springboard", "get the springboard apps list", null, headers)
            .pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.logSvc.logError(err);
            return of(empty);
        }));
    }
    /**
     * sets a token for CartusOnline authentication
     */
    getColToken(token, partyId) {
        const intUrl = this.appConfig.getConfig("integrationapi");
        const headers = new HttpHeaders({ 'authorization': token, 'app-context': partyId });
        return this.baseClient.getExt(intUrl + 'v1/navigateToCOL', 'get the CartusOnline token', null, headers, true).pipe(map(r => r), catchError((err, source) => {
            const empty = null;
            return of(empty);
        }));
    }
    /**
     * retrieve app url from config
     */
    getAppUrlFromName(appName) {
        const url = this.appConfig.getConfig(appName);
        return url;
    }
}
SpringboardSetupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpringboardSetupService_Factory() { return new SpringboardSetupService(i0.ɵɵinject(i1.AppConfigService), i0.ɵɵinject(i2.BaseClientService), i0.ɵɵinject(i3.RemoteLoggingService)); }, token: SpringboardSetupService, providedIn: "root" });
