var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { AppConfigService } from './app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
import * as i3 from "ngx-cookie-service";
import * as i4 from "./base-client.service";
import * as i5 from "./remote-logging.service";
export class AuthenticationService {
    constructor(http, appConfig, cookieService, baseClient, logSvc) {
        this.http = http;
        this.appConfig = appConfig;
        this.cookieService = cookieService;
        this.baseClient = baseClient;
        this.logSvc = logSvc;
        this.authClient = new OktaAuth({
            clientId: String(this.appConfig.getConfig('oktaClientId')),
            issuer: String(this.appConfig.getConfig('oktaUrl')),
            redirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
            postLogoutRedirectUri: String(this.appConfig.getConfig('oktaRedirectUri')),
            tokenManager: {
                storage: 'sessionStorage',
                autoRenew: false
            }
        });
        this.authClient.authStateManager.subscribe(authState => {
            // handle emitted latest authState
        });
        if (!this.authClient.isLoginRedirect()) {
            // Trigger an initial authState change event when the app startup
            this.authClient.authStateManager.updateAuthState();
        }
    }
    /** Component Angular destructor lifecycle hook */
    ngOnDestroy() {
        if (this.authClient.authStateManager.subscribe) {
            this.authClient.authStateManager.unsubscribe();
        }
    }
    /** Service call for login */
    login(user) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const data = {
                    username: user.email,
                    password: user.password
                };
                this.transaction = yield this.authClient.signInWithCredentials(data);
                return yield this.getToken(this.transaction);
            }
            catch (err) {
                return err;
            }
        });
    }
    /** Service call for signOut and revoke */
    signOut() {
        return __awaiter(this, void 0, void 0, function* () {
            // We need to revoke the token before closing it.
            yield this.authClient.revokeAccessToken();
            yield this.authClient.closeSession()
                .then(() => {
            })
                .catch(e => {
                if (e.xhr && e.xhr.status === 429) {
                    console.error('Too many requests.');
                }
            });
        });
    }
    /** Service call for to get tokens */
    getToken(transactions) {
        return __awaiter(this, void 0, void 0, function* () {
            let receivedTokens;
            if (transactions && transactions.status === 'SUCCESS') {
                let oldToken = yield this.getOldToken();
                yield this.authClient.token.getWithoutPrompt({
                    responseType: ['code', 'token', 'id_token'],
                    sessionToken: transactions.sessionToken,
                    scopes: ['openid', 'offline_access', 'email'],
                })
                    .then(res => {
                    receivedTokens = res.tokens;
                    if (receivedTokens.idToken && receivedTokens.accessToken) {
                        this.authClient.tokenManager.add('idToken', receivedTokens.idToken);
                        this.authClient.tokenManager.add('accessToken', receivedTokens.accessToken);
                        this.revokeOldToken(oldToken);
                    }
                });
            }
            return {
                transaction: transactions,
                tokens: receivedTokens
            };
        });
    }
    /** Service call for SSO authentication */
    trySso() {
        return __awaiter(this, void 0, void 0, function* () {
            let oldToken = yield this.getOldToken();
            return yield this.authClient.token.getWithoutPrompt().then(tokenOrTokens => {
                if (tokenOrTokens) {
                    this.authClient.tokenManager.setTokens({
                        accessToken: tokenOrTokens.tokens.accessToken,
                        idToken: tokenOrTokens.tokens.idToken
                    });
                    this.revokeOldToken(oldToken);
                }
                console.log('getWithoutPrompt()', tokenOrTokens); // Leave this debug code in place
                return tokenOrTokens;
            }).catch(err => {
                // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
                return; // Not authenticated
            });
        });
    }
    /** Call to handle change user password */
    changeUserPassword(passwordDetail) {
        const urlStr = sessionStorage.getItem('urlPath');
        let appContext;
        if (urlStr) {
            appContext = urlStr.replace(/^(?:[^\/]*\/){2}\s*/, '').slice(0, -1);
        }
        const headers = {
            'app-context': `${appContext}`,
            'authorization': this.cookieService.get('car-ses-tok')
        };
        const body = {
            'oldPassword': {
                'value': passwordDetail.oldPassword
            },
            'newPassword': {
                'value': passwordDetail.newPassword
            }
        };
        const url = String(this.appConfig.getConfig('changePasswordEndpoint'));
        return this.baseClient.postUnauthorizedHandler(url, body, headers).pipe(map(r => r.body), catchError((err, source) => {
            this.logSvc.logError(err);
            return of(err);
        }));
    }
    /** To reset the incorrect password attempts
     * @param headers for the request
     */
    resetPasswordAttempts(headers) {
        const url = String(this.appConfig.getConfig('resetPasswordEndpoint'));
        return this.baseClient.post(url, null, headers).pipe(map(r => r.body), catchError((err, source) => {
            const empty = null;
            this.logSvc.logError(err);
            return of(empty);
        }));
    }
    /** Service call to refresh session */
    refreshSession() {
        return __awaiter(this, void 0, void 0, function* () {
            let oldToken = yield this.getOldToken();
            return this.authClient.token.renew(oldToken).then(freshToken => {
                console.log('refreshSession()', freshToken); // Leave this debug code in place
                if (freshToken) {
                    this.revokeOldToken(oldToken);
                    return freshToken;
                }
            }).catch(err => {
                // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
                return; // Not authenticated
            });
        });
    }
    /** Service call to revoke old token */
    revokeOldToken(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            if (accessToken) {
                console.log('revokeOldToken()');
                this.authClient.revokeAccessToken(accessToken);
            }
        });
    }
    /** Service call to get old token */
    getOldToken() {
        return __awaiter(this, void 0, void 0, function* () {
            let oldToken;
            yield this.authClient.tokenManager.getTokens().then(tokens => {
                if (tokens && tokens.accessToken) {
                    oldToken = tokens.accessToken;
                }
            });
            return oldToken;
        });
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.CookieService), i0.ɵɵinject(i4.BaseClientService), i0.ɵɵinject(i5.RemoteLoggingService)); }, token: AuthenticationService, providedIn: "root" });
