import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from './base-service';
import { ApiConfigService } from './api-config.service';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "./api-config.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./http-error-handler.service";
/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
export class BaseClientService extends BaseService {
    /**
     * base constructor
     * @param config API Config service injector
     * @param http HTTP Client injector
     * @param errorHandler HTTP error handler injector
     */
    constructor(config, http, errorHandler) {
        super(config, http);
        this.errorHandler = errorHandler;
    }
    /** Run a GET API call, expecting a response with a single model
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param action The action that is performing the request
     * @return A response containing the expected model (single)
     */
    getById(route, action = 'error executing requests') {
        return this.http.get(this.rootUrl + route, { params: this.newParams(), observe: 'response', responseType: 'json' })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a GET API call, expectiing a response with an array of the expected model
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param action The action that is performing the request
     * @return A response containing the expected models (array)
     */
    get(route, action = 'error executing requests') {
        return this.http.get(this.rootUrl + route, { params: this.newParams(), observe: 'response', responseType: 'json' })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a PUT API call
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param body The object that is being updated
     * @param action The action that is performing the request
     * @return A response containing the expected result (single)
     */
    put(route, body, action = 'error putting request') {
        const url = this.rootUrl + route;
        return this.http.put(url, body, { params: this.newParams(), observe: 'response', responseType: 'json' })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a POST API call
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param body The object that is being posted
     * @param params The http params for the request
     * @param action The action that is performing the request
     * @return A response containing the expected result (single)
     */
    post(route, body, headers, action = 'error posting request') {
        const url = this.rootUrl + route;
        return this.http.post(url, body, { headers: headers, params: this.newParams(), observe: 'response', responseType: 'json' })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a POST API call without piping to the errorHandler service (unless 401 received) so it returns the full response
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param body The object that is being posted
     * @param params The http params for the request
     * @param action The action that is performing the request
     * @return A response containing the full result (single)
     */
    postUnauthorizedHandler(route, body, headers, action = 'error posting request') {
        const url = this.rootUrl + route;
        return this.http.post(url, body, { headers: headers, params: this.newParams(), observe: 'response', responseType: 'json' })
            .pipe(catchError((error) => {
            if (error.status === 401) {
                return of(this.errorHandler.handleHttpErrorResponse(action, false)(error, null));
            }
            return throwError(error);
        }));
    }
    /** Run an external POST API call
     * @param route The endpoint for the request (ie. - '/v1/reports_recent')
     * @param body The object that is being posted
     * @param action The action that is performing the request
     * @return A response containing the expected result (single)
     */
    postExt(url, body, action = 'error posting request') {
        return this.http.post(url, body, { params: this.newParams(), observe: 'response', responseType: 'json' })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run an external GET API call
   * @param url The endpoint for the request
   * @param action The action that is performing the request
   * @param params The http params for the request
   * @param headers The http request headers for the request
   * @return A response containing the expected result (single)
   */
    getExt(url, action = 'error executing requests', params = new HttpParams(), headers = new HttpHeaders(), withCredentials) {
        return this.http.get(url, { headers: headers, params: params, observe: 'response', responseType: 'json', withCredentials: withCredentials })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a DELETE API call
     * @param route The endpoint for the delete request
     * @param action The action that is performing the request
     * @return A response containing the expected result
     */
    delete(route, action = 'error delete request') {
        const url = this.rootUrl + route;
        return this.http.delete(url, { params: this.newParams(), observe: 'response', responseType: 'json' })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
    /** Run a GET API call without piping to the errorHandler service so it returns the full response
     * @param route The endpoint for the request (ie. - '/v1/reports_recent)
     * @param action The action that is performing the request
     * @return A response containing the expected model (single)
     */
    getNoErrorHandler(route, action = 'error executing requests') {
        return this.http.get(this.rootUrl + route, { params: this.newParams(), observe: 'response', responseType: 'json' });
    }
    /** Run a GET API call with options
     * @param route The url for the request
     * @param action The action that is performing the request
     * @param params The http params for the request
     * @param headers The http request headers for the request
     * @return A response containing the expected model (single)
     */
    getWithOptions(route, action = 'error executing requests', params = new HttpParams(), headers = new HttpHeaders()) {
        return this.http.get(this.rootUrl + route, { headers: headers, params: params, observe: 'response', responseType: 'json' })
            .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
}
BaseClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseClientService_Factory() { return new BaseClientService(i0.ɵɵinject(i1.ApiConfigService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.HttpErrorHandlerService)); }, token: BaseClientService, providedIn: "root" });
