<div class="userContextContainer" *ngIf="selectedContext">
  <div [matMenuTriggerFor]="settingMenu" class="mat-menu" fxLayout="row" fxLayoutAlign="center center">
    <div class="userImageSection">
      <app-avatar [firstName]="selectedContext.firstName" [lastName]="selectedContext.lastName"></app-avatar>
    </div>
    <div class="userNameSection" fxHide.lt-md>
      <button mat-button class="menu-style" aria-hidden="true" [matMenuTriggerFor]="settingMenu">
        <div class="menuName">
          <a href="javascript:" (focus)="announceContext()" role="button" aria-label="menu clickable">
            <span aria-hidden="true">{{selectedContext.firstName}}</span>
            <span class="client-name" aria-hidden="true">{{selectedContext.clientLegalName}}</span>
          </a>
        </div>
        <div class="menuIconBlock" [ngClass]="{'auto': !selectedContext.clientLegalName && !selectedContext.clientNo }">
          <mat-icon class="expandMore">arrow_drop_down</mat-icon>
          <mat-icon class="expandLess">arrow_drop_up</mat-icon>
        </div>
      </button>
    </div>
    <div fxHide.gt-sm class="mobile-userContext">
      <button class="mobile-menu-style" mat-button>
        <div class="menuIconBlock">
          <mat-icon class="expandMore">expand_more</mat-icon>
          <mat-icon class="expandLess">expand_less</mat-icon>
        </div>
      </button>
    </div>
  </div>
  <mat-menu class="settingMenu" #settingMenu="matMenu">
    <button mat-menu-item *ngIf="this.displayContextOption && this.contextsList && this.contextsList.count > 1" (click)="changeContext()">
      <mat-icon>people</mat-icon>Select Account
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon> Log Out
    </button>
  </mat-menu>
</div>
