<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<form fxLayout="row" [formGroup]="selfRegistrationSetupForm" fxFlex>
    <div class="registration-form">
        <div fxLayout="column">
            <div class="title">
                {{templateString.FORM_TITLE}}
            </div>
            <div fxLayout="row" fxLayoutGap="40px" fxLayoutGap.xs="0">
                <div fxFlex="60" fxFlex.xs="100%" fxFlex.sm="100%">
                    <div class="mat-form-input">
                        <mat-form-field appearance="fill">
                            <mat-label>{{templateString.EMAIL}}</mat-label>
                            <input matInput placeholder="Email" formControlName="email" (blur)="checkForUsername()">
                            <mat-error>{{ getErrorMessage('EMAIL') }}</mat-error>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="responsiveView">
                        <ng-container *ngTemplateOutlet="passwordInstructionTemplate"></ng-container>
                    </ng-container>
                    <div class="mat-form-input">
                        <mat-form-field appearance="fill">
                            <mat-label>{{templateString.PASS}}</mat-label>
                            <input matInput placeholder="Password" type="password" formControlName="password"
                                (ngModelChange)="checkPassword()" (blur)="checkForUsername()">
                            <mat-error>{{ getErrorMessage('PASSWORD') }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="mat-form-input">
                        <mat-form-field appearance="fill">
                            <mat-label>{{templateString.CONFIRM_PASS}}</mat-label>
                            <input matInput placeholder="Confirm Password" type="password"
                                formControlName="confirmpassword" (ngModelChange)="checkPassword()">
                            <mat-error>{{ getErrorMessage('CONFIRMPASSWORD') }}</mat-error>
                            <mat-error
                                *ngIf="selfRegistrationSetupForm.controls['confirmpassword'].hasError('notSame')">
                                Passwords Does not match
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex="40" fxHide.xs fxHide.sm fxLayoutAlign="end end">
                    <ng-container *ngIf="!responsiveView">
                        <ng-container *ngTemplateOutlet="passwordInstructionTemplate"></ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="login-contain-btn">
                <div class="privacy-notice" *ngIf="isCandidateorTransferee">
                    {{templateString.PRIVACY_NOTICE_TXT}}
                    <span class="link-style" *ngIf="isCandidateorTransferee">
                        <a target="_blank" [routerLink]="['/privacyNotice']">Privacy Notice</a>
                    </span>
                </div>
                <button (click)="createUser()" [disabled]="!selfRegistrationSetupForm.valid" id="saveBtn">
                    {{templateString.CREATE_ACC_BTN}}
                </button>
            </div>
        </div>
    </div>

    <ng-template #passwordInstructionTemplate>
        <div class="password-instruction">
            <div class="heading">
                {{templateString.PASSWORD_INSTRUCTION_TITLE}}
            </div>
            <ul class="instruction-list">
                <li *ngFor="let instruction of passwordInstructions">
                    {{instruction}}
                </li>
            </ul>
        </div>
    </ng-template>
</form>