/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./left-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./left-panel.component";
var styles_LeftPanelComponent = [i0.styles];
var RenderType_LeftPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LeftPanelComponent, data: {} });
export { RenderType_LeftPanelComponent as RenderType_LeftPanelComponent };
export function View_LeftPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "leftPanel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Technology That Moves You"]))], null, null); }
export function View_LeftPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-left-panel", [], null, null, null, View_LeftPanelComponent_0, RenderType_LeftPanelComponent)), i1.ɵdid(1, 114688, null, 0, i2.LeftPanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeftPanelComponentNgFactory = i1.ɵccf("app-left-panel", i2.LeftPanelComponent, View_LeftPanelComponent_Host_0, {}, {}, []);
export { LeftPanelComponentNgFactory as LeftPanelComponentNgFactory };
