import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class ManageUserSharedService {
    /**
     * Base Constructor
     */
    constructor() {
        /** Instance of BehaviorSubject of type UserDetails */
        this.userDetails = new BehaviorSubject(null);
        /** UserDetails as observable */
        this.loginUserDetails = this.userDetails.asObservable();
    }
    /**
     * Function to Update the UserDetails subject
     * @param data updated User
     */
    updateData(data) {
        this.userDetails.next(data);
    }
}
ManageUserSharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManageUserSharedService_Factory() { return new ManageUserSharedService(); }, token: ManageUserSharedService, providedIn: "root" });
