import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

/** Avatar component to display the user Initials */
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class:'app-avatar' }
})
export class AvatarComponent implements OnInit {

  /** the First Name data to be displayed */
  @Input() firstName: string;

  /** the Last Name data to be displayed */
  @Input() lastName: string;

  /** Base constructor method */
  constructor() { }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
  }

}
